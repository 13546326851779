import type { GoogleAnalyticsConfig as GoogleAnalyticsConfigType } from '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/types';
import { GoogleAnalyticsConfig } from './google-analytics-config';

export class GoogleAnalyticsConfigs {
  constructor(
    private readonly googleAnalyticsConfigs: GoogleAnalyticsConfigType[] | [],
  ) {}

  findByAppId(appId: string): GoogleAnalyticsConfig {
    const googleAnalyticsConfig = this.googleAnalyticsConfigs?.find(
      (_googleAnalyticsConfig) => _googleAnalyticsConfig.id === appId,
    );
    return new GoogleAnalyticsConfig(googleAnalyticsConfig);
  }
}
