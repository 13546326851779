import { isSSR } from '@wix/app-market-services';
import { useEssentials } from '../contexts';

export function useCreateGoogleAnalytics(gTag?: string) {
  const { googleAnalytics } = useEssentials();
  if (gTag) {
    const isAnalyticsConsentApproved =
      !isSSR() &&
      window.consentPolicyManager?.getCurrentConsentPolicy().policy?.analytics;
    if (isAnalyticsConsentApproved) {
      googleAnalytics.initialize(gTag);
      return googleAnalytics;
    }
  }

  return undefined;
}
