import { EAppBadges } from '../apps-badges';
import type { AppBadgeType } from '../apps-badges';
import type { MarketApp as MarketAppType } from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';
import type { IMarketApp } from './types';

export class MarketApp {
  constructor(private readonly marketApp: MarketAppType | undefined) {}

  private hasBadge(badgeType: string): boolean {
    return (
      this.marketApp?.badges?.some((badge) => badge.badgeType === badgeType) ??
      false
    );
  }

  get appId(): string {
    return this.marketApp?.appId || '';
  }

  get supportEmails(): string[] {
    return this.marketApp?.contactEmails?.supportEmails || [];
  }

  get status(): string {
    return this.marketApp?.versionStatus ?? '';
  }

  get badges(): AppBadgeType[] {
    const marketAppBadges: AppBadgeType[] = [];

    if (this.hasBadge(EAppBadges.WIX_CHOICE)) {
      marketAppBadges.push({
        badge: EAppBadges.WIX_CHOICE,
        payload: {
          priority: 2,
        },
      });
    } else if (this.hasBadge(EAppBadges.BETA)) {
      marketAppBadges.push({
        badge: EAppBadges.BETA,
        payload: {
          priority: 4,
        },
      });
    }
    return marketAppBadges;
  }

  toJSON(): IMarketApp {
    return {
      appId: this.appId,
      status: this.status,
      badges: this.badges,
      contactEmails: {
        supportEmails: this.supportEmails,
      },
    };
  }
}
