import { useEssentials } from '../../../contexts/essentials-context';
import { useJunkYard } from '../../../contexts/junk-yard-context';
import {
  getAppsSuggestionsFromVespa,
  getAutocompleteSuggestionsFromVespa,
} from '../api';
import type { AppSuggestion, OnSelectParams } from '@wix/app-market-components';
import { OptionTypes, SearchTypes } from '@wix/app-market-components';
import {
  appMarketAppImpression,
  appModalOpenAppModalClick,
  searchSearchClick,
  searchSearchFocus,
} from '@wix/bi-logger-app-market-data/v2';
import { getPathForBi } from '../../../bi-services/utils';
import {
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../../contexts';
import { RoutePath } from '../../../models';

const { APP, SEARCH } = OptionTypes;

export const useSearchBoxActions = (
  pageName: string,
  searchLocation: string,
): {
  onChange(searchTerm: string): Promise<{
    autocompleteSuggestions: string[];
    appsSuggestions: AppSuggestion[];
  }>;
  onSelect(params: OnSelectParams): void;
  onEnterPressed(searchTerm: string): void;
  onFocus(): void;
} => {
  const { routerData } = useJunkYard();
  const { biLogger, experiments } = useEssentials();
  const { languageCode } = useSiteContext();
  const { router } = useRouterContext();
  const services = useServices();

  function sendSearchSearchClickBi(
    searchTerm: string,
    searchType: string,
    suggestionIndex?: number,
  ) {
    biLogger.report(
      searchSearchClick({
        search_term: searchTerm,
        search_type: searchType,
        search_location: searchLocation,
        index: suggestionIndex,
        tag_type: getPathForBi(routerData.route?.path),
      }),
    );
  }

  function sendAppMarketAppImpressionBi(
    searchTerm: string,
    appIndex: number | undefined,
    appId: string,
  ) {
    biLogger.report(
      appMarketAppImpression({
        app_id: appId,
        impression_type: 'search',
        section: 'app_suggestion',
        tag_type: 'search_bar',
        search_term: searchTerm,
        section_index: appIndex,
        page_name: pageName,
      }),
    );
  }
  function sendAppModalOpenAppModalClickBi(
    searchTerm: string,
    appIndex: number | undefined,
    appId: string,
    slug: string,
  ) {
    biLogger.report(
      appModalOpenAppModalClick({
        app_id: appId,
        index: appIndex,
        navigation_source: searchLocation,
        tag_type: 'search_bar',
        section: 'app_suggestion',
        search_term: searchTerm,
        slug_id: slug,
      }),
    );
  }
  async function onChange(searchTerm: string) {
    const [autocompleteSuggestions, appsSuggestions] = await Promise.all([
      getAutocompleteSuggestionsFromVespa({
        services,
        experiments,
        searchTerm,
      }).then((response) => response.suggestions ?? []),
      getAppsSuggestionsFromVespa({
        services,
        searchTerm,
        languageCode,
      }).then((response) => response.suggestions),
    ]);

    appsSuggestions.forEach((suggestion, index) => {
      sendAppMarketAppImpressionBi(searchTerm, index, suggestion.id);
    });

    return { autocompleteSuggestions, appsSuggestions };
  }

  function onFocus() {
    biLogger.report(
      searchSearchFocus({
        search_location: searchLocation,
      }),
    );
  }

  function onSelectSeeMoreApps(searchTerm: string, index?: number) {
    sendSearchSearchClickBi(searchTerm, SearchTypes.MORE_RESULTS, index);
    router.navigateTo({
      path: RoutePath.SEARCH_RESULTS,
      payload: {
        query: searchTerm,
      },
    });
  }

  function onSelectAutoComplete(
    searchTerm: string,
    index?: number,
    label?: string,
  ) {
    sendSearchSearchClickBi(searchTerm, SearchTypes.POPULAR, index);
    router.navigateTo({
      path: RoutePath.SEARCH_RESULTS,
      payload: {
        query: label ?? '',
      },
    });
  }

  function onSelectSearchResultsApp(
    searchTerm: string,
    index?: number,
    appId?: string,
    slug?: string,
  ) {
    sendAppModalOpenAppModalClickBi(searchTerm, index, appId ?? '', slug ?? '');
    sendSearchSearchClickBi(searchTerm, SearchTypes.APPS, index);
    router.navigateTo({
      path: RoutePath.APP_PAGE,
      payload: {
        slug: slug ?? '',
        searchLocation,
      },
    });
  }

  function onSelect({
    optionId,
    appId,
    index,
    type,
    slug,
    label,
    searchTerm,
  }: OnSelectParams) {
    if (optionId.includes('see more apps')) {
      onSelectSeeMoreApps(searchTerm, index);
    } else if (type === SEARCH) {
      onSelectAutoComplete(searchTerm, index, label);
    } else if (type === APP) {
      onSelectSearchResultsApp(searchTerm, index, appId, slug);
    }
  }

  function onEnterPressed(searchTerm: string) {
    sendSearchSearchClickBi(searchTerm, SearchTypes.DIRECT);
    router.navigateTo({
      path: RoutePath.SEARCH_RESULTS,
      payload: {
        query: searchTerm,
      },
    });
  }

  return { onChange, onSelect, onFocus, onEnterPressed };
};
