import React from 'react';
import s from './styles/pricing-plans.module.scss';
import { Box, Card, Heading, Text } from '@wix/design-system';
import { ConfirmSmall } from '@wix/wix-ui-icons-common';
import { PlanPrice } from './plan-price';
import { useEssentials } from '../../contexts/essentials-context';
import { pricingPlansSectionDataHooks } from '../../exported-components/pricing-plans-section/data-hooks';
import type { Discount, PlanDescription } from '@wix/app-market-services';
import { WixSaleBadge } from '../badges/wix-sale-badge';
import { DeveloperSaleBadge } from '../badges/dev-sale-badge';
import { AppBenefitsBadge } from '../badges/app-benefits-badge';

export interface PlanBoxProps {
  planTitle: string;
  priceTitle?: string;
  priceData?: PriceData;
  description: PlanDescription;
  priceSubTitle?: string;
  isMobile: boolean;
  index: number;
  discount?: Discount;
  isExternalPricing: boolean;
  appName: string;
}

export interface PriceData {
  decodedSign: string;
  price: string;
  discountPrice?: string;
  period?: string;
}

export function PlanBox({
  planTitle,
  priceTitle,
  priceData,
  description,
  isMobile,
  priceSubTitle,
  index,
  discount,
  isExternalPricing,
  appName,
}: PlanBoxProps) {
  const { t, experiments } = useEssentials();

  const showNovemberSaleBadge =
    experiments?.enabled('specs.app-market.Sale') ?? false;
  const showDeveloperSaleBadge =
    experiments?.enabled('specs.app-market.DeveloperSaleBadge') ?? false;
  const showStrikethrough =
    experiments?.enabled('specs.app-market.pricingStrikethrough') ?? false;
  const showBenefit =
    experiments?.enabled('specs.app-market.AppBenefits') ?? false;

  const currencySymbol = priceData?.decodedSign;

  function addCurrencySymbol(outline: string, symbol: string | undefined) {
    const decimalNumberRegex = /\d+(\.\d+)?/;

    const match = outline.match(decimalNumberRegex);

    if (!match) {
      return outline;
    }

    const decimalNumber = match[0];
    const decimalNumberIndex = outline.indexOf(decimalNumber);

    return (
      outline.slice(0, decimalNumberIndex) +
      symbol +
      outline.slice(decimalNumberIndex)
    );
  }

  return (
    <Card
      showShadow={false}
      className={s.pricingPlanContainerDynamic}
      dataHook={`plan-box-container-${index}`}
    >
      <Box
        marginTop={4}
        marginRight={4}
        marginBottom={4}
        marginLeft={4}
        direction="vertical"
      >
        <Box marginBottom={1} align="space-between">
          <Box>
            <Text
              dataHook={pricingPlansSectionDataHooks.planBoxTitle}
              size="small"
              weight="normal"
            >
              {planTitle}
            </Text>
          </Box>
          <Box>
            {showBenefit &&
              priceData?.discountPrice &&
              discount?.sourceType === 'APP_BENEFIT' && (
                <AppBenefitsBadge appName={appName} />
              )}
            {showNovemberSaleBadge &&
            (priceData?.discountPrice || isExternalPricing) &&
            discount?.saleType === 'NOVEMBER_SALE' ? (
              <WixSaleBadge amount={discount.amount} />
            ) : showDeveloperSaleBadge &&
              discount?.saleType === 'DEVELOPER_SALE' ? (
              <DeveloperSaleBadge amount={discount.amount} />
            ) : undefined}
          </Box>
        </Box>
        <Box direction="vertical">
          <Box direction="vertical" verticalAlign="bottom" marginBottom={3}>
            {priceTitle ? (
              <Box marginTop="2px">
                <Heading
                  size="large"
                  dataHook={`plan-box-${index}-price-title`}
                >
                  {priceTitle}
                </Heading>
              </Box>
            ) : (
              priceData && (
                <PlanPrice
                  decodedSign={priceData.decodedSign}
                  price={priceData.price}
                  discountPrice={priceData.discountPrice}
                  period={priceData.period ?? t('solution.page.pricingMonth')}
                  isMobile={isMobile}
                  index={index}
                  showStrikethrough={showStrikethrough}
                />
              )
            )}

            {priceSubTitle && (
              <Box>
                <Text size="medium" weight="thin" secondary>
                  {addCurrencySymbol(priceSubTitle, currencySymbol)}
                </Text>
              </Box>
            )}
          </Box>

          <Box direction="vertical" align="left" dataHook="plan-description">
            {description.benefits?.map((bullet: string, bulletIndex) => {
              return (
                <Box
                  key={`plan-${index}-description-line-${bulletIndex}`}
                  direction="horizontal"
                  verticalAlign="middle"
                  align="center"
                  marginBottom={
                    description.benefits &&
                    bulletIndex === description.benefits.length - 1
                      ? '0px'
                      : '9px'
                  }
                  inline
                  className={s.stylingBenefit}
                >
                  <Box className={s.stylingConfirm}>
                    <ConfirmSmall width="18px" height="18px" />
                  </Box>
                  <Text className={s.benefitTextBigBoxDynamic}>{bullet}</Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
