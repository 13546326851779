import React from 'react';

export default function AppBenefitsBannerImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
    >
      <path
        d="M24.4757 34.501L37.848 32.3136C40.4096 31.8945 42.826 33.6315 43.245 36.1932L45.4324 49.5655C45.8515 52.1272 44.1145 54.5435 41.5528 54.9626L28.1805 57.15C25.6188 57.569 23.2025 55.832 22.7835 53.2703L20.5961 39.8981C20.177 37.3364 21.914 34.92 24.4757 34.501Z"
        fill="#28BF9A"
      />
      <path
        d="M46.7285 66.2916L49.8339 53.0098C50.4274 50.4715 52.9662 48.8949 55.5046 49.4884L68.7864 52.5938C71.3247 53.1873 72.9013 55.7261 72.3078 58.2644L69.2024 71.5462C68.6089 74.0846 66.07 75.6611 63.5317 75.0677L50.2499 71.9622C47.7116 71.3687 46.135 68.8299 46.7285 66.2916Z"
        fill="#28BF9A"
      />
      <path
        d="M21.1458 26.2327L74.7613 38.9365L76.8501 30.1206L23.2346 17.4167L21.1458 26.2327Z"
        fill="#C2E2FF"
      />
      <path
        d="M22.9998 83.9989L72.9998 83.9902L72.9954 58.9902L22.9954 58.9989L22.9998 83.9989Z"
        fill="#C2E2FF"
      />
      <path
        d="M28.2901 48.6303L31.7601 40.1503L37.7501 47.0803L28.2901 48.6303Z"
        fill="#C2E2FF"
      />
      <path
        d="M60.23 26.3601C60.8866 26.1733 61.4565 25.7615 61.8402 25.197C62.2239 24.6324 62.3969 23.9509 62.329 23.2717C62.261 22.5925 61.9565 21.9588 61.4686 21.4814C60.9807 21.004 60.3406 20.7133 59.66 20.6601C59.5754 20.6552 59.4937 20.6271 59.4241 20.5787C59.3545 20.5304 59.2996 20.4637 59.2654 20.3861C59.2313 20.3085 59.2193 20.223 59.2307 20.139C59.2421 20.055 59.2764 19.9758 59.33 19.9101C59.7511 19.3723 59.9695 18.7035 59.9471 18.0208C59.9247 17.3381 59.6629 16.6851 59.2075 16.176C58.7521 15.667 58.1321 15.3343 57.4561 15.2363C56.7801 15.1384 56.0912 15.2813 55.51 15.6401C55.4389 15.6864 55.3563 15.712 55.2715 15.7142C55.1866 15.7165 55.1028 15.6952 55.0294 15.6527C54.9559 15.6103 54.8955 15.5484 54.8551 15.4738C54.8146 15.3992 54.7955 15.3149 54.8 15.2301C54.8174 14.5545 54.5992 13.8939 54.1827 13.3617C53.7661 12.8295 53.1774 12.4588 52.5174 12.3133C51.8574 12.1679 51.1674 12.2566 50.5657 12.5644C49.964 12.8722 49.4883 13.3798 49.22 14.0001C49.1885 14.0777 49.1363 14.1452 49.0692 14.1953C49.002 14.2454 48.9225 14.2763 48.8392 14.2845C48.7558 14.2927 48.6718 14.2781 48.5961 14.242C48.5205 14.206 48.4562 14.15 48.41 14.0801C48.0312 13.508 47.4623 13.0881 46.8039 12.8947C46.1455 12.7013 45.4399 12.7468 44.8118 13.0232C44.1837 13.2995 43.6734 13.789 43.3712 14.4051C43.0689 15.0212 42.9942 15.7242 43.16 16.3901C43.1801 16.4716 43.1775 16.557 43.1525 16.6371C43.1275 16.7172 43.0811 16.7889 43.0183 16.8445C42.9555 16.9001 42.8786 16.9375 42.7961 16.9526C42.7135 16.9677 42.6285 16.9599 42.55 16.9301C41.9653 16.7141 41.3267 16.6916 40.7282 16.8658C40.1297 17.04 39.603 17.4018 39.2256 17.8979C38.8482 18.3941 38.64 18.9982 38.6318 19.6215C38.6236 20.2448 38.8158 20.8543 39.18 21.3601L49.0377 23.7016L46.9902 32.3496L48.9364 32.8104L50.9836 24.1638L60.23 26.3601Z"
        fill="#116DFF"
      />
      <path d="M48.99 59H46.99V84H48.99V59Z" fill="#116DFF" />
    </svg>
  );
}
