import ReactGA from 'react-ga4';
import type {
  AppMarketAnalyticsPlatform,
  EventName,
  EventParamsMap,
} from './types';

export class GoogleAnalytics {
  private platform: AppMarketAnalyticsPlatform;
  private deviceType: 'desktop' | 'mobile';
  private userId: string | undefined;
  private siteId: string | undefined;
  private isInitialized: boolean;

  constructor(
    platform: AppMarketAnalyticsPlatform,
    deviceType: 'desktop' | 'mobile',
    userId: string | undefined,
    siteId: string | undefined,
  ) {
    this.platform = platform;
    this.deviceType = deviceType;
    this.userId = userId;
    this.siteId = siteId;
    this.isInitialized = false;
  }

  initialize(trackingId: string) {
    if (!trackingId) {
      throw new Error('Google Analytics tracking ID is required.');
    }
    if (this.isInitialized) {
      return;
    }

    this.initializeReactGA(trackingId);
    this.isInitialized = true;
  }

  private initializeReactGA(trackingId: string) {
    ReactGA.initialize([
      {
        trackingId,
        gaOptions: {
          userId: this.userId,
        },
      },
    ]);
  }

  reportAutomaticEvent({
    name,
    params,
  }: {
    name: EventName;
    params: EventParamsMap[EventName];
  }) {
    if (!this.isInitialized) {
      console.error('Google Analytics is not initialized.');
      return;
    }

    if (!name) {
      console.error('Event name is required and cannot be empty.');
      return;
    }

    this.sendToReactGA(name, {
      ...params,
      platform: this.platform,
      deviceType: this.deviceType,
      user: this.userId,
      siteId: this.siteId,
    });
  }

  private sendToReactGA(name: EventName, params: EventParamsMap[EventName]) {
    ReactGA.send({
      hitType: name,
      ...params,
    });
  }

  reportCustomEvent({
    name,
    category,
    params,
  }: {
    name: EventName;
    category: string;
    params: EventParamsMap[EventName];
  }) {
    if (!this.isInitialized) {
      console.error('Google Analytics is not initialized.');
      return;
    }

    if (!name) {
      console.error('Event name is required and cannot be empty.');
      return;
    }

    this.logCustomEventToReactGA(name, category, params);
  }

  private logCustomEventToReactGA(
    name: EventName,
    category: string,
    params: EventParamsMap[EventName],
  ) {
    ReactGA.event({
      action: name,
      label: name,
      category,
      ...params,
    });
  }
}
