import React, { useEffect, useMemo } from 'react';
import { Box, Page } from '@wix/design-system';
import { PageHeader, AppsSection } from '@wix/app-market-components';
import {
  appMarketSubCategoryClick,
  appMarketPageView,
  appMarketBackButtonClick,
  appMarketPageStartLoadedSrc24Evid163,
} from '@wix/bi-logger-app-market-data/v2';
import s from '../pages.scss';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import {
  useJunkYard,
  useEssentials,
  useServices,
  useRouterContext,
  useSiteContext,
} from '../../contexts';
import { AppMarketPage } from '../../enums';
import { convertStringToBi } from '../../bi-services/utils';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { QueryDecorator } from '../../decorators';
import type { CategoryPageData } from './api/category-page-data';
import { getCategoryPageData } from './api/category-page-data';
import { CategorySkeleton } from './category-skeleton';
import { RoutePath } from '../../models/router';
import { BackButton } from '../../components/back-button';
import { withSaleBanner } from '../../components/sale-banner';
import { categoryPageId } from './category-page-id';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';

export interface CategoryPageComponentProps extends CategoryPageData {
  slug: string;
  onLoad: () => void;
}

function CategoryPageComponent({
  slug,
  category,
  appGroupSections,
  onLoad,
}: CategoryPageComponentProps) {
  useMemo(() => onLoad(), [slug]);
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const { routerData } = useJunkYard();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const page = AppMarketPage.CATEGORY;

  useEffect(() => {
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          pageType: page,
          pageName: slug,
          subCategories: appGroupSections.map((section) => section.tagSlug),
        }),
      ),
    );
  }, []);

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'collection_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  function onButtonClick(subCategorySlug: string, categorySlug: string) {
    biLogger.report(
      appMarketSubCategoryClick({
        type: 'view_more_apps',
        subcategory_name: convertStringToBi(subCategorySlug),
        category_name: convertStringToBi(category.slug),
      }),
    );
    router.navigateTo({
      path: RoutePath.SUB_CATEGORY,
      payload: {
        parentSlug: categorySlug,
        slug: subCategorySlug,
        subCat: subCategorySlug,
        referral: 'view_more_apps',
      },
    });
  }

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        {router.hasHistory() && (
          <Box marginTop="18px">
            <BackButton
              size="small"
              weight="thin"
              skin="dark"
              onBack={onBack}
            />
          </Box>
        )}
        <Box
          dataHook="collection-page-header"
          marginTop={router.hasHistory() ? '6px' : '18px'}
          direction="vertical"
        >
          <PageHeader
            primaryTitle={category.description}
            secondaryTitle={category.name}
            imageUrl={category.image}
          />
        </Box>
        {appGroupSections.map((appGroupSection, index) => {
          return (
            <Box direction="vertical" marginTop="36px">
              <AppsSection
                {...appsSectionSharedPropsMapper(
                  appGroupSection,
                  page,
                  slug,
                  index,
                  undefined,
                  undefined,
                  {
                    path: RoutePath.SUB_CATEGORY,
                    payload: {
                      parentSlug: category.slug,
                      slug: appGroupSection.tagSlug,
                      subCat: appGroupSection.tagSlug,
                      referral: 'view_more_apps',
                    },
                  },
                )}
                onButtonClick={() =>
                  onButtonClick(appGroupSection.tagSlug, category.slug)
                }
                titleSize="medium"
              />
            </Box>
          );
        })}
      </Page.Content>
    </Page>
  );
}

export interface CategoryPageProps {
  slug: string;
}

export const CategoryPage = withSaleBanner(function ({
  slug,
}: CategoryPageProps) {
  const reportLoadFinish = useComponentLoadReporter({
    componentId: categoryPageId,
    params: { categorySlug: slug },
    deps: [slug],
  });
  const { biLogger } = useEssentials();
  const { languageCode } = useSiteContext();
  const services = useServices();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.CATEGORY,
      }),
    );
  }, [slug]);

  return (
    <QueryDecorator
      queryFn={() =>
        getCategoryPageData({
          services,
          slug,
          languageCode,
        })
      }
      queryKey={`${categoryPageId}-${slug}-${languageCode}`}
    >
      {({ data: categoryPageData, isLoading }) =>
        isLoading ? (
          <CategorySkeleton />
        ) : (
          <CategoryPageComponent
            {...categoryPageData}
            slug={slug}
            onLoad={reportLoadFinish}
          />
        )
      }
    </QueryDecorator>
  );
});
