import * as React from 'react';
import style from './create-website-banner.scss';
import bannerStyle from './banner.scss';
import type { TFunction } from 'react-i18next';
import { Button, Box, Heading } from '@wix/design-system';

export const PublicDevCenterBanner = ({ t }: { t: TFunction }) => {
  return (
    <Box
      direction="vertical"
      align="center"
      backgroundColor="#162d3d"
      maxHeight="312px"
      className={bannerStyle.banner}
    >
      <Heading appearance="H2" light className={style.titleBanner}>
        {t('standalone.devCenterBanner.new-title')}
      </Heading>
      <Box marginTop="14px" className={style.buttonWrapper}>
        <Button
          skin="light"
          priority="secondary"
          size="large"
          as="a"
          href="https://dev.wix.com/docs/build-apps"
          target="_blank"
          dataHook="dev-center-banner-cta"
        >
          {t('standalone.devCenterBanner.button')}
        </Button>
      </Box>
    </Box>
  );
};
