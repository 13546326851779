export enum Path {
  CATEGORY = 'category',
  SUB_CATEGORY = 'sub-category',
  COLLECTION = 'collection',
  WEB_SOLUTION = 'web-solution',
  HOME = '',
  SEARCH = 'search-result',
  SHARE = 'share-app',
  UNLISTED_APPS = 'install',
  FINISH_SETUP = 'finish-setup',
  FINISH_UPDATE = 'finish-update',
  MANAGE_APPS = 'manage-apps',
  RECOMMENDATION = 'recommendation',
  DEVELOPER = 'developer',
  OPEN_APP_WITH_PARAMS = 'open-app-with-params',
}

export enum Origin {
  EDITOR = 'editor',
  BIZMGR = 'bizmgr',
  STANDALONE = 'standalone',
}

export enum ReviewsSortBy {
  MostRecent = '-publishedAt',
  MostHelpful = '-totalLikes',
  Rating = '-rating',
}

export enum AppPageMode {
  PREVIEW = 'preview',
  TEST = 'test',
}

export enum OriginQueryParam {
  EDITOR = 'editor_platform',
  BIZMGR = 'business_manager_platform',
  STANDALONE = 'stand_alone_platform',
  ADI_DASHBOARD = 'adi%20in%20bm',
}

export enum EditorType {
  EDITOR = 'EDITOR',
  EDITORX = 'EDITORX',
  ADI = 'ADI',
  STUDIO = 'STUDIO',
}

export enum MarketPermissions {
  MANAGE_APPS = 'app-market.manage-app',
  UPGRADE_APP = 'app-market.upgrade-app',
  UPDATE_APP = 'APP_MARKET.UPDATE_APP',
  INSTALL_APP = 'APP_MARKET.INSTALL_APP',
  UNINSTALL_APPS = 'APP_MARKET.UNINSTALL_APPS',
  DELETE_APP = 'APP_MARKET.DELETE_APP',
  ADD_AGAIN = 'APP_MARKET.ADD_AGAIN',
  OPEN_APP = 'APP_MARKET.OPEN_APP',
}

export enum StatusCode {
  NOT_FOUND = 404,
}

export enum ErrorPageType {
  INTERNAL_SERVER = '500',
  NOT_FOUND = '404',
  NOT_AVAILABLE_APP = 'not-available-app',
}
