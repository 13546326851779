import type { AppBadge } from './types';
import { AppBadgesType } from './types';

export function isAppWithBenefits(appBadges: AppBadge[]) {
  const appWithBenefits = appBadges.find(
    ({ badge }) =>
      badge === AppBadgesType.PREMIUM_BENEFIT ||
      badge === AppBadgesType.APP_BENEFIT,
  );
  if (appWithBenefits?.badge === 'PREMIUM_BENEFIT') {
    return appWithBenefits?.bundleAppBadgePayload?.appBundleType ? true : false;
  } else if (appWithBenefits?.badge === 'APP_BENEFIT') {
    return true;
  } else {
    return false;
  }
}
