import React from 'react';
import { Box, SectionHelper, TextButton, Text } from '@wix/design-system';
import {
  appMarketAppPageOneDependencyImpression,
  appMarketAppPageOneDependencyClick,
} from '@wix/bi-logger-app-market-data/v2';
import { useTranslation } from '../../../../../contexts/translations-context';
import { ManyDependenciesNoneInstalledNotificationDataHooks } from './data-hooks';
import { useEssentials, useRouterContext } from '../../../../../contexts';
import { RoutePath } from '../../../../../models';

export interface ManyDependenciesNoneInstalledNotificationProps {
  app: {
    id: string;
    name: string;
  };
  appDependencies: {
    id: string;
    name: string;
    slug: string;
  }[];
}

export function ManyDependenciesNoneInstalledNotification({
  app,
  appDependencies,
}: ManyDependenciesNoneInstalledNotificationProps) {
  const { biLogger } = useEssentials();
  const { t } = useTranslation();
  const { router } = useRouterContext();

  const navigateToDependencyAppPage = (appDependency: {
    id: string;
    slug: string;
  }) => {
    router.navigateTo({
      path: RoutePath.APP_PAGE,
      payload: {
        slug: appDependency.slug,
        referral: `app-dependency-${appDependency.id}`,
        referralSectionName: app.name,
      },
    });
  };

  React.useEffect(() => {
    biLogger.report(
      appMarketAppPageOneDependencyImpression({
        app_id: app.id,
        dependency_type: 'app',
        app_dependency: appDependencies.map((dep) => dep.id).toString(),
        type: 'multiple',
      }),
    );
  }, []);

  return (
    <SectionHelper
      dataHook={ManyDependenciesNoneInstalledNotificationDataHooks.container}
      appearance="danger"
      fullWidth={true}
      title={
        <Box direction="vertical">
          <Text size="small" weight="bold">
            {t('solution.page.appDependenciesNotInstalled.title', {
              appDependency: app.name,
              interpolation: { escapeValue: false },
            })}
          </Text>
          <Box marginTop="6px" direction="horizontal">
            {appDependencies.map((appDependency, index) => {
              return (
                <>
                  <TextButton
                    size="small"
                    underline="always"
                    skin="dark"
                    dataHook={ManyDependenciesNoneInstalledNotificationDataHooks.dependencyLink(
                      appDependency.slug,
                    )}
                    onClick={() => {
                      biLogger.report(
                        appMarketAppPageOneDependencyClick({
                          app_id: app.id,
                          dependency_type: 'app',
                          click_type: 'app',
                          app_dependency: appDependencies
                            .map((dep) => dep.id)
                            .toString(),
                          type: 'multiple',
                        }),
                      );
                      navigateToDependencyAppPage(appDependency);
                    }}
                  >
                    <Text size="small" weight="normal">
                      {appDependency.name}
                    </Text>
                  </TextButton>
                  {index !== appDependencies.length - 1 &&
                    appDependency.name && (
                      <Box marginLeft="3px" marginRight="3px">
                        {' / '}
                      </Box>
                    )}
                </>
              );
            })}
          </Box>
        </Box>
      }
    >
      <Box marginTop="12px">
        <Text
          dataHook={
            ManyDependenciesNoneInstalledNotificationDataHooks.description
          }
          size="small"
          weight="thin"
        >
          {t('solution.page.appDependenciesNotInstalled.subtitle')}
        </Text>
      </Box>
    </SectionHelper>
  );
}
