import type {
  ManagedAppsRequest,
  CompleteSetupCountRequest,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type { QueryTagRequest } from '@wix/ambassador-prime-auto-v1-tag/types';
import type {
  GetAppsByTagRequest,
  QueryTagAppRequest,
} from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import type { GetWebSolutionsRequest } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';
import type {
  GetAppsByAppIdsRequest,
  GetDynamicSectionsRequest,
  GetTagSectionsRequest,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { ListAppPlansByAppIdRequest } from '@wix/ambassador-appmarket-v1-app-plans/types';
import type { CompleteInstallationRequest } from '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/types';
import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { GetPricingModelRequest } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import type {
  GetSitesPremiumStatusRequest,
  GetSitesPremiumStatusResponse,
} from '@wix/ambassador-premium-v1-asset/types';
import type { GetAppComponentsByAppIdsRequest } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type {
  AutocompleteRequest,
  SearchRequest,
} from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import type { BundleAppsRequest } from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/types';
import type { GetFreeTrialEligibilityRequest } from '@wix/ambassador-premium-store-v1-dynamic-offering-service-entity/types';
import type { GetEligibleAppsForFreeTrialRequest } from '@wix/ambassador-appmarket-v1-app-subscriptions/types';

/**
 * Rpc services implementation, based on rpcClient/ambassador-bootstrap.
 * @class RpcServices
 */
export class RpcServices {
  private readonly rpcClient: NonNullable<AmbassadorBootstrap>;
  constructor(rpcClient: AmbassadorBootstrap) {
    if (!rpcClient) {
      throw new Error('RpcClient is required but provided as undefined.');
    }
    this.rpcClient = rpcClient;
  }

  async getBundleApps(payload: BundleAppsRequest) {
    const getBundleAppsRpc = (
      await import('@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/rpc')
    ).bundleApps;
    return this.rpcClient.request(getBundleAppsRpc(payload));
  }

  async getManagedApps(payload: ManagedAppsRequest) {
    const managedAppsRpc = (
      await import('@wix/ambassador-devcenter-managedapps-v1-managed-app/rpc')
    ).managedApps;
    return this.rpcClient.request(managedAppsRpc(payload));
  }

  async completeSetupCount(payload: CompleteSetupCountRequest) {
    const completeSetupCount = (
      await import('@wix/ambassador-devcenter-managedapps-v1-managed-app/rpc')
    ).completeSetupCount;
    return this.rpcClient.request(completeSetupCount(payload));
  }

  async queryTag(payload: QueryTagRequest) {
    const queryTagRpc = (await import('@wix/ambassador-prime-auto-v1-tag/rpc'))
      .queryTag;
    return this.rpcClient.request(queryTagRpc(payload));
  }

  async queryTagApp(payload: QueryTagAppRequest) {
    const queryTagAppRpc = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc')
    ).queryTagApp;
    return this.rpcClient.request(queryTagAppRpc(payload));
  }
  async getAppsByTag(payload: GetAppsByTagRequest) {
    const getAppsByTagRpc = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc')
    ).getAppsByTag;
    return this.rpcClient.request(getAppsByTagRpc(payload));
  }

  async getAppsByAppIds(payload: GetAppsByAppIdsRequest) {
    const getAppsByAppIdsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getAppsByAppIds;
    return this.rpcClient.request(getAppsByAppIdsRpc(payload));
  }

  async getDynamicSections(payload: GetDynamicSectionsRequest) {
    const getDynamicSectionsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getDynamicSections;
    return this.rpcClient.request(getDynamicSectionsRpc(payload));
  }

  async getWebSolutionsBase(payload: GetWebSolutionsRequest) {
    const getWebSolutionsRpc = (
      await import('@wix/ambassador-devcenter-marketplace-v1-web-solution/rpc')
    ).getWebSolutionsBase;
    return this.rpcClient.request(getWebSolutionsRpc(payload));
  }

  async queryAppPlans(payload: ListAppPlansByAppIdRequest) {
    const queryAppPlansRpc = (
      await import('@wix/ambassador-appmarket-v1-app-plans/rpc')
    ).listAppPlansByAppId;
    return this.rpcClient.request(queryAppPlansRpc(payload));
  }

  async completePendingInstallation(payload: CompleteInstallationRequest) {
    const completeInstallationRpc = (
      await import(
        '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/rpc'
      )
    ).completeInstallation;
    return this.rpcClient.request(completeInstallationRpc(payload));
  }

  async getTagSections(payload: GetTagSectionsRequest) {
    const getTagSectionsRpc = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/rpc'
      )
    ).getTagSections;
    return this.rpcClient.request(getTagSectionsRpc(payload));
  }

  async getPricingModel(payload: GetPricingModelRequest) {
    const getPricingModelRpc = (
      await import('@wix/ambassador-devcenter-pricing-v1-pricing-entity/rpc')
    ).getPricingModel;
    return this.rpcClient.request(getPricingModelRpc(payload));
  }

  async getSitesPremiumStatus(
    payload: GetSitesPremiumStatusRequest,
  ): Promise<GetSitesPremiumStatusResponse> {
    const getSitesPremiumStatusRpc = (
      await import('@wix/ambassador-premium-v1-asset/rpc')
    ).getSitesPremiumStatus;
    return this.rpcClient.request(getSitesPremiumStatusRpc(payload));
  }

  async searchApps(payload: SearchRequest) {
    const searchAppsRpc = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/rpc')
    ).search;
    return this.rpcClient.request(searchAppsRpc(payload));
  }

  async getAppComponentsByAppIds(payload: GetAppComponentsByAppIdsRequest) {
    const getAppComponentsByAppIds = (
      await import(
        '@wix/ambassador-devcenter-app-components-v1-app-components/rpc'
      )
    ).getAppComponentsByAppIds;
    return this.rpcClient.request(getAppComponentsByAppIds(payload));
  }

  async getAutoCompleteSuggestions(payload: AutocompleteRequest) {
    const autocompleteSuggestions = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/rpc')
    ).autocomplete;
    return this.rpcClient.request(autocompleteSuggestions(payload));
  }

  async getFreeTrialEligibility(payload: GetFreeTrialEligibilityRequest) {
    const getFreeTrialEligibility = (
      await import(
        '@wix/ambassador-premium-store-v1-dynamic-offering-service-entity/rpc'
      )
    ).getFreeTrialEligibility;
    return this.rpcClient.request(getFreeTrialEligibility(payload));
  }

  async getEligibleAppsForFreeTrial(
    payload: GetEligibleAppsForFreeTrialRequest,
  ) {
    const getEligibleAppsForFreeTrial = (
      await import('@wix/ambassador-appmarket-v1-app-subscriptions/rpc')
    ).getEligibleAppsForFreeTrial;
    return this.rpcClient.request(getEligibleAppsForFreeTrial(payload));
  }
}
