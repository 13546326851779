import {
  TagSectionType,
  type Section,
} from '@wix/ambassador-appmarket-v1-section/types';
import type {
  AppBenefitSection,
  HomepageSection,
  SidebarSection,
} from './types';
import {
  DynamicSectionType,
  TagSectionSkin,
  TagSectionSortMethod,
} from './types';

export class MarketSection {
  constructor(private readonly section: Section) {}

  toSidebarSection(): SidebarSection {
    if (this.section.sideBar) {
      return {
        id: this.section.dealerOfferId ?? '',
        title: this.section.sideBar?.title ?? '',
        tagIds: this.section.sideBar?.tagIds ?? [],
      };
    }

    throw new Error('Section is not a sidebar section');
  }

  toAppBenefitSection(): AppBenefitSection {
    if (this.section.app) {
      return {
        id: this.section.dealerOfferId ?? '',
        appId: this.section.app.appId ?? '',
      };
    }

    throw new Error('Section is not an app benefit section');
  }

  toHomePageSection(): HomepageSection {
    if (this.section.tagList) {
      return {
        id: this.section.dealerOfferId ?? '',
        type: DynamicSectionType.TAGS,
        tagIds: this.section.tagList.tagIds ?? [],
      };
    }

    if (this.section.tag) {
      return {
        id: this.section.dealerOfferId ?? '',
        type: DynamicSectionType.TAG,
        tagId: this.section.tag.tagId ?? '',
        sortMethods: this.section.tag.sortMethod
          ? [this.section.tag.sortMethod as unknown as TagSectionSortMethod]
          : [TagSectionSortMethod.NO_SORT],
        skin:
          !this.section.tag.type ||
          this.section.tag.type === TagSectionType.UNKNOWN
            ? TagSectionSkin.COLLECTION
            : (this.section.tag.type as unknown as TagSectionSkin),
      };
    }

    throw new Error('Section is not a homepage section');
  }
}
