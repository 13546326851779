import * as React from 'react';
import { composer } from '../../../../component-decorators/composer';
import type { ISolutionBoxProps } from '../solution-box';
import { ConditionalLinkRenderer } from '../solution-box';
import style from './solution-box-compact.scss';
import { SolutionTopSection } from './solution-top-section';
import { SolutionDetails } from './solution-details';
import { SolutionBottomAction } from './solution-bottom-action-bar';
import { ExternalLink } from '@wix/wix-ui-icons-common';
import {
  sendSolutionImpressionBi,
  sendOpenSolutionPageBi,
  sendOpenWixOfferingBi,
} from '../../../../bi/events';
import {
  getAppBadgeTheme,
  getAppBadgeTitle,
  getAppBadgeTooltipContent,
} from '../../../common';
import { VisibilityDetector } from '../../../../utils/visibility-detector';
import { getClientImageURL } from '../../../common/getClientImageURL';
import { Box, TextButton } from '@wix/design-system';
import { ApiType } from '@wix/ambassador-marketplace/types';

export const SolutionBoxCompact = composer()
  .withTranslation()
  .withExperiments()
  .compose(
    ({
      solution,
      solutionBiData,
      onBoxClick,
      isInstalled,
      showPremiumLabel,
      experiments,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      savedPrice,
      isVoucherApp,
      layout,
      to,
      t,
      as,
      hideRate,
      hideTagsBadge,
      isAppSetupIncomplete,
    }: ISolutionBoxProps) => {
      const {
        icon,
        teaser,
        numberOfReviews,
        reviewStars,
        name,
        pricing,
        company,
      } = solution;

      const [isImpression, setIsImpression] = React.useState(false);

      const onImpression = () => {
        if (!isImpression) {
          if (isWixOffering) {
            sendOpenWixOfferingBi(solutionBiData, solution.name, 'impression');
          } else {
            sendSolutionImpressionBi(solutionBiData);
          }
          setIsImpression(true);
        }
      };

      const isWixOffering = solution.type === ApiType.WIX_OFFERING;

      const badgeTitle = getAppBadgeTitle(
        isBundleAppInstalled,
        isBundleAppNotInstalled,
        solution.Tags,
        experiments,
        hideTagsBadge,
        solution?.id,
        isWixOffering,
      );
      const showHotelsToolTip = badgeTitle === 'solutionBox.badge.beta';

      return (
        <VisibilityDetector onVisible={onImpression}>
          <Box
            className={hideRate ? style.solutionBoxNoRate : style.solutionBox}
            dataHook="solution-box"
            paddingTop="0"
            paddingBottom="0"
          >
            <div
              data-appid={solution.id}
              onClick={() => {
                if (isWixOffering) {
                  sendOpenWixOfferingBi(solutionBiData, solution.name, 'click');
                } else {
                  sendOpenSolutionPageBi(solutionBiData);
                }
                onBoxClick(solution);
              }}
              className={
                layout && layout !== 'marketplace'
                  ? style.solutionBoxWrapperIntegrationsLayout
                  : style.solutionBoxWrapperMarketplaceLayout
              }
            >
              <ConditionalLinkRenderer to={to} as={as}>
                <SolutionTopSection
                  alt={`${name} logo`}
                  solutionIcon={getClientImageURL(icon, {
                    toHeight: 42,
                    toWidth: 42,
                  })}
                  badgeTitle={badgeTitle}
                  badgeTheme={getAppBadgeTheme(badgeTitle)}
                  showTooltip={
                    isBundleAppInstalled ||
                    isBundleAppNotInstalled ||
                    showHotelsToolTip
                  }
                  tooltipContent={getAppBadgeTooltipContent(
                    isBundleAppInstalled,
                    isBundleAppNotInstalled,
                    isVoucherApp,
                    showHotelsToolTip,
                  )}
                  solutionName={name}
                  isWixOffering={isWixOffering}
                />
                <SolutionDetails
                  teaser={teaser}
                  name={name}
                  isWixOffering={isWixOffering}
                  companyName={company?.name}
                  experiments={experiments}
                />
              </ConditionalLinkRenderer>
              <Box height="29px" />
              {isWixOffering ? (
                <TextButton
                  size="small"
                  weight="thin"
                  onClick={() => {}}
                  suffixIcon={<ExternalLink />}
                >
                  {t('solutionBox.link.wix-offering', {
                    wixOffering: solution.name,
                  })}
                </TextButton>
              ) : (
                <SolutionBottomAction
                  isInstalled={isInstalled}
                  showPremiumLabel={showPremiumLabel}
                  pricing={pricing}
                  reviews={{ numberOfReviews, reviewStars }}
                  isBundleAppInstalled={isBundleAppInstalled}
                  isBundleAppNotInstalled={isBundleAppNotInstalled}
                  isAppSetupIncomplete={isAppSetupIncomplete}
                  savedPrice={savedPrice}
                  isVoucherApp={isVoucherApp}
                  hideRate={hideRate}
                  companyId={solution?.company?.id}
                  isPreInstalled={solution.isPreInstalled}
                />
              )}
            </div>
          </Box>
        </VisibilityDetector>
      );
    },
  );
