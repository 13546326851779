import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { IHttpClient } from '@wix/http-client';
import { MarketSectionsService } from '../market-sections';
import { BundleAppsService } from '../bundle-apps';

export class AppBenefitsService {
  private readonly marketSectionsService: MarketSectionsService;
  private readonly bundleAppsService: BundleAppsService;

  constructor(rpcClient?: AmbassadorBootstrap, httpClient?: IHttpClient) {
    this.marketSectionsService = new MarketSectionsService(
      rpcClient,
      httpClient,
    );
    this.bundleAppsService = new BundleAppsService(rpcClient, httpClient);
  }

  public async get({
    includeDealerBenefits,
  }: {
    includeDealerBenefits: boolean;
  }): Promise<{ appId: string }[]> {
    const [realBenefits, bundleAppsBenefits] = await Promise.all([
      includeDealerBenefits
        ? this.marketSectionsService.getAppBenefitsSections()
        : Promise.resolve([]),
      this.bundleAppsService.get(),
    ]);
    const benefitsAppIds = Array.from(
      new Set([
        ...realBenefits.map((benefit) => benefit.appId),
        ...bundleAppsBenefits.map((benefit) => benefit.appId),
      ]),
    );

    return benefitsAppIds.map((appId) => ({ appId }));
  }
}
