import { RequestService } from '../request-service';
import { AppsPlans } from './apps-plans';
import type {
  ListAppPlansByAppIdRequest as AppPlansRequest,
  ListAppPlansByAppIdResponse as AppPlansResponse,
} from '@wix/ambassador-appmarket-v1-app-plans/types';
import type RpcModule from '@wix/ambassador-appmarket-v1-app-plans/rpc';
import type HttpModule from '@wix/ambassador-appmarket-v1-app-plans/http';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class AppsPlansService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-appmarket-v1-app-plans/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-appmarket-v1-app-plans/http');
  }

  public async list(payload: { appIds: string[] }): Promise<AppsPlans> {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in App Plans');
    }
    const response = await this.request<AppPlansRequest, AppPlansResponse>({
      methodName: 'listAppPlansByAppId',
      payload: {
        appIds: payload.appIds,
      },
    });
    return new AppsPlans(response || []);
  }
}
