import {
  Box,
  CounterBadge,
  Divider,
  SidebarItemNext,
} from '@wix/design-system';
import { SettingsSmall } from '@wix/wix-ui-icons-common';
import React from 'react';
import { useTranslation } from '../../../contexts/translations-context';
import { constantSidebarKeys } from '../sidebar-constants';
import { RoutePath } from '../../../models';

interface SidebarFooterProps {
  pendingAppsCount: number;
  onClick(): void;
}

export function SidebarFooter({
  pendingAppsCount,
  onClick,
}: SidebarFooterProps) {
  const { t } = useTranslation();

  return (
    <>
      <Divider direction="horizontal" />

      <SidebarItemNext
        itemKey={constantSidebarKeys[RoutePath.MANAGE_APPS]}
        onClick={onClick}
        dataHook="sidebar-footer.manage-apps"
        suffix={
          pendingAppsCount ? (
            <CounterBadge skin="danger">{pendingAppsCount}</CounterBadge>
          ) : null
        }
      >
        <Box verticalAlign="middle" gap="20px">
          <SettingsSmall />
          {t('sidebar.manage-apps')}
        </Box>
      </SidebarItemNext>
    </>
  );
}
