import { useEssentials } from '../contexts';
import type { ReportParams } from '@wix/app-market-watch';

/**
 * Hook to report the start and finish of a component load.
 *
 * @param componentId - unique identifier for the component, use lowercase kebab-case.
 * @param deps - dependencies array to report load start and finish again when any of them change,
 * for example, we can pass the queryKey used in react query, because when it changes the component will re-fetch data.
 * @returns A function to report the component finished loading.
 */
export function useComponentLoadReporter({
  componentId,
  params = {},
  deps = [],
}: {
  componentId: string;
  params?: ReportParams;
  deps?: string[];
}): () => void {
  const { marketWatch } = useEssentials();
  const componentMonitor = marketWatch.monitor(componentId, deps.toString());
  componentMonitor.reportLoadStart(params);

  return () => componentMonitor.reportLoadFinish(params);
}
