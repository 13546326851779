import { RequestService } from '../request-service';
import {
  SectionsPlacement,
  type GetSectionRequest,
  type GetSectionResponse,
} from '@wix/ambassador-appmarket-v1-section/types';
import type RpcModule from '@wix/ambassador-appmarket-v1-section/rpc';
import type HttpModule from '@wix/ambassador-appmarket-v1-section/http';
import { MarketSections } from './market-sections';
import type { AppBenefitSection, SidebarSection } from './types';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class MarketSectionsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-appmarket-v1-section/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-appmarket-v1-section/http');
  }

  public async getSidebarSections({
    hasSiteContext,
  }: {
    hasSiteContext: boolean;
  }): Promise<SidebarSection[]> {
    const response = await this.request<GetSectionRequest, GetSectionResponse>({
      methodName: 'getSection',
      payload: {
        placement: SectionsPlacement.SIDE_BAR,
        customParams: {
          isMetaSiteId: JSON.stringify(hasSiteContext),
        },
      },
    });

    const marketSections = new MarketSections(response.sections);
    return marketSections.toSidebarSections();
  }

  public async getAppBenefitsSections(): Promise<AppBenefitSection[]> {
    const response = await this.request<GetSectionRequest, GetSectionResponse>({
      methodName: 'getSection',
      payload: {
        placement: SectionsPlacement.APP_BENEFITS,
        customParams: {
          LineItemsAsFamily: 'any',
        },
      },
    });

    const marketSections = new MarketSections(response.sections);
    return marketSections.toAppBenefitSections();
  }

  public async getHomePageSections({
    hasSiteContext,
  }: {
    hasSiteContext: boolean;
  }) {
    const response = await this.request<GetSectionRequest, GetSectionResponse>({
      methodName: 'getSection',
      payload: {
        placement: SectionsPlacement.PAGE,
        customParams: {
          isMetaSiteId: JSON.stringify(hasSiteContext),
        },
      },
    });

    const marketSections = new MarketSections(response.sections);
    return marketSections.toHomePageSections();
  }
}
