import React from 'react';
import { Button, MarketingLayout, CloseButton, Card } from '@wix/design-system';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';
import AppBenefitsBannerImage from './app-benefits-banner-image';

export interface AppBenefitsBannerProps extends ExternalComponentProps {
  onClose: () => void;
  onMainActionClick: () => void;
}

export function AppBenefitsBannerComponent({
  onClose,
  onMainActionClick,
}: AppBenefitsBannerProps) {
  const { t } = useEssentials();

  return (
    <Card
      controls={<CloseButton skin="dark" onClick={onClose} size="medium" />}
    >
      <MarketingLayout
        title={t('app.benefits.banner.title')}
        description={t('app.benefits.banner.description')}
        actions={
          <Button size="small" onClick={onMainActionClick}>
            {t('app.benefits.banner.button')}
          </Button>
        }
        size="small"
        inverted
        image={<AppBenefitsBannerImage />}
      />
    </Card>
  );
}

export const AppBenefitsBanner = withEssentialsContext(
  AppBenefitsBannerComponent,
);
