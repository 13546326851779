enum EventName {
  PAGE_VIEW = 'pageview',
  INSTALL_CLICK = 'install_button_click',
}
interface EventParamsMap {
  [EventName.PAGE_VIEW]: PageViewParams;
  [EventName.INSTALL_CLICK]: InstallClickParams;
}

type AutomaticEvent<T extends EventName> = {
  name: T;
  params: EventParamsMap[T];
};
type CustomEvent<T extends EventName> = {
  name: T;
  category: string;
  params: EventParamsMap[T];
};

type IEvent =
  | AutomaticEvent<EventName.PAGE_VIEW>
  | CustomEvent<EventName.INSTALL_CLICK>;

interface PageViewParams {
  referringPage: string;
  referringPageDetails: string;
  appPosition: number;
  deviceType: 'desktop' | 'mobile';
  platform: string;
  user: string;
  siteId: string;
}

interface InstallClickParams {}

export { IEvent, EventName, EventParamsMap };
