import type { Services } from '@wix/app-market-services';
import { Placement } from '@wix/app-market-services';
import type { DynamicSectionType } from './types';
import {
  completeSetupCountId,
  fallbackSidebarSections,
  getDynamicSidebarItemsId,
  sidebarComponentId,
} from './sidebar-constants';
import { fetchWithQuery } from '../../decorators';
import type { QueryClient } from '@tanstack/react-query';
import type { MarketWatch } from '@wix/app-market-watch';

export async function getDynamicSidebarItems({
  services,
  queryClient,
  marketWatch,
  isMetaSiteId,
}: {
  services: Services;
  queryClient: QueryClient;
  marketWatch: MarketWatch;
  isMetaSiteId: boolean;
}): Promise<DynamicSectionType[]> {
  try {
    const { sections } = await fetchWithQuery({
      queryClient,
      queryFn: () =>
        services.getDynamicSections({
          placement: Placement.SIDEBAR,
          isMetaSiteId,
        }),
      queryKey: getDynamicSidebarItemsId,
    });

    return sections;
  } catch (e) {
    const error = new Error(
      '[sidebar-api.ts] (getDynamicSidebarItems) failed: ',
      {
        cause: e,
      },
    );
    marketWatch.monitor(sidebarComponentId).reportError(error);
    return fallbackSidebarSections;
  }
}

export async function getCompleteSetupCount({
  services,
  queryClient,
  marketWatch,
  moveBundleToBenefits,
}: {
  services: Services;
  queryClient: QueryClient;
  marketWatch: MarketWatch;
  moveBundleToBenefits: boolean;
}): Promise<number> {
  try {
    return fetchWithQuery({
      queryClient,
      queryFn: () =>
        services.completeSetupCount({
          hideWixApps: false,
          includeOldBundles: false,
          excludeBundles: moveBundleToBenefits,
        }),
      queryKey: completeSetupCountId,
    });
  } catch (e) {
    const error = new Error(
      '[sidebar-api.ts] (getCompleteSetupCount) failed: ',
      {
        cause: e,
      },
    );
    marketWatch.monitor(sidebarComponentId).reportError(error);
    return 0;
  }
}
