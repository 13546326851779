import React, { useEffect, useState } from 'react';
import { AppPage } from './app-page';
import { useRouterContext, useServices } from '../../contexts';
import { RoutePath } from '../../models';

export function UnlistedAppPage({ shareUrlId }: { shareUrlId: string }) {
  const services = useServices();
  const [appId, setAppId] = useState<string | undefined>(undefined);
  const [isActive, setIsActive] = useState(false);
  const { router } = useRouterContext();

  useEffect(() => {
    (async () => {
      const dcShareUrl = await services.devCenterShareUrl.get({ shareUrlId });
      if (dcShareUrl.activisionStatus === 'DISABLED') {
        return;
      }
      setIsActive(true);
      const app = await services.marketApp.get({ appId: dcShareUrl.appId });
      if (app.status === 'PUBLISHED') {
        router.navigateTo(
          {
            path: RoutePath.APP_PAGE,
            payload: {
              slug: dcShareUrl.appId,
              referral: `unlisted-app-${dcShareUrl.appId}`,
            },
          },
          { replace: true },
        );
      } else {
        setAppId(dcShareUrl.appId);
      }
    })();
  }, []);

  if (!appId || !isActive) {
    return null;
  }
  return (
    <AppPage
      id={appId}
      status="APPROVED"
      options={{
        pricingOptions: undefined,
        showRating: false,
        showReviews: false,
        showRecommendedForYou: false,
      }}
    />
  );
}
