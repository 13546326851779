import React from 'react';
import { Origin } from '../../enums/marketplace-enums';
import s from './marketplace-router.scss';
import { config } from '../../config';
import { ShareAppContainer } from '../share-app';
import { FinishSetupContainer } from '../finish-setup';
import { FinishUpdateContainer } from '../finish-update';
// loadable
import { ManageApps } from '../manage-apps';
import { ErrorBoundary } from '../error-boundary';
import { MarketplaceRouterSeo } from './marketplace-router-seo';
import { DeveloperPageContainer } from '../developer-page-container';
import { OpenAppWithParams } from '../open-app-with-params';
import { composer } from '../../component-decorators/composer';
import { usePageViewEvent } from '../../hooks/use-page-view-event';
import {
  CollectionPage,
  CategoryPage,
  SubCategoryPage,
  SearchResultsPage,
  HomePage,
  AppPage,
  UnlistedAppPage,
  useRouterContext,
  RoutePath,
  useEssentials,
} from '@wix/app-market-core';

function uuidValidate(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

const Route = () => {
  const { route: newRoute } = useRouterContext();
  const { experiments } = useEssentials();

  switch (newRoute.path) {
    case RoutePath.HOME:
      return <HomePage />;

    case RoutePath.APP_PAGE:
      const { slug, appId, status } = newRoute.payload;
      const isSlug = !uuidValidate(appId);

      return (
        <AppPage {...(isSlug ? { slug, status } : { id: appId, status })} />
      );

    case RoutePath.CATEGORY:
      return <CategoryPage slug={newRoute.payload.slug} />;

    case RoutePath.SUB_CATEGORY:
      return (
        <SubCategoryPage
          slug={newRoute.payload.slug}
          parentSlug={newRoute.payload.parentSlug}
        />
      );

    case RoutePath.COLLECTION:
      return <CollectionPage slug={newRoute.payload.slug} />;

    case RoutePath.SEARCH_RESULTS:
      return <SearchResultsPage searchTerm={newRoute.payload.query.trim()} />;

    case RoutePath.SHARE:
      return experiments.enabled('specs.app-market.ShareAppWithAppPage') ? (
        <AppPage
          id={newRoute.payload.appId}
          options={{
            pricingOptions: undefined,
            showRating: false,
            showReviews: false,
            showRecommendedForYou: false,
          }}
        />
      ) : (
        <ShareAppContainer
          appDefId={newRoute.payload.appId}
          shareId={newRoute.payload.shareId}
          version={newRoute.payload.version}
          experiments={experiments}
        />
      );
    case RoutePath.UNLISTED_APP:
      return <UnlistedAppPage shareUrlId={newRoute.payload.shareUrlId} />;
    case RoutePath.DEVELOPER:
      return <DeveloperPageContainer slug={newRoute.payload.slug} />;

    case RoutePath.FINISH_SETUP:
      return (
        <FinishSetupContainer
          slug={newRoute.payload.appId || newRoute.payload.slug}
          appVersion={newRoute.payload.version}
        />
      );
    case RoutePath.FINISH_UPDATE:
      return (
        <FinishUpdateContainer
          slug={newRoute.payload.appId}
          appVersion={newRoute.payload.version}
        />
      );
    case RoutePath.MANAGE_APPS:
      return (
        <ManageApps
          manageAppsDeepLinkData={newRoute.payload?.manageAppsDeepLinkData}
        />
      );
    case RoutePath.OPEN_APP_WITH_PARAMS:
      return (
        <OpenAppWithParams
          appId={newRoute.payload.appId}
          options={newRoute.payload.openAppOptions}
        />
      );
    default:
      return <HomePage />;
  }
};

export const MarketplaceRouter = composer()
  .withMarketplaceStore()
  .withTranslation()
  .withExperiments()
  .compose(() => {
    const marketPlaceRouterStyles =
      config.origin === Origin.STANDALONE
        ? s.marketplaceRouterSa
        : config.origin === Origin.EDITOR
        ? s.marketplaceRouterEditor
        : s.marketplaceRouter;

    usePageViewEvent();

    return (
      <div data-testid="marketplace-router" className={marketPlaceRouterStyles}>
        <ErrorBoundary>
          {config.origin === Origin.STANDALONE && <MarketplaceRouterSeo />}
          <Route />
        </ErrorBoundary>
      </div>
    );
  });
