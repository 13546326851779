/* <--- home page sections types ---> */

export enum DynamicSectionType {
  TAG = 'TAG',
  TAGS = 'TAGS',
}

export enum TagSectionSortMethod {
  NO_SORT = 'NO_SORT',
  INSTALLS = 'INSTALLS',
  GEO = 'GEO',
  REVIEWS_RATING = 'REVIEWS_RATING',
  APP_MARKET_SCORE = 'APP_MARKET_SCORE',
  ML_MODEL_RANKING = 'ML_MODEL_RANKING',
  MANUAL = 'MANUAL',
  DEALER = 'DEALER',
}

export enum TagSectionSkin {
  COLLECTION = 'COLLECTION',
  PROMO = 'PROMO',
}

export type TagsSection = {
  id: string;
  type: DynamicSectionType.TAGS;
  tagIds: string[];
};

export type TagSection = {
  id: string;
  type: DynamicSectionType.TAG;
  tagId: string;
  sortMethods: TagSectionSortMethod[];
  skin: TagSectionSkin;
};
export type HomepageSection = TagsSection | TagSection;
/* <--- home page sections types ---> */

export type SidebarSection = { id: string; title: string; tagIds: string[] };
export type AppBenefitSection = { id: string; appId: string };
