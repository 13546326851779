import { RequestService } from '../request-service';
import { MarketApps } from './market-apps';
import { MarketApp } from './market-app';
import type {
  GetMarketAppRequest,
  GetMarketAppResponse,
  QueryMarketAppRequest,
  QueryMarketAppResponse,
} from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';
import type RpcModule from '@wix/ambassador-devcenter-market-apps-v1-market-app/rpc';
import type HttpModule from '@wix/ambassador-devcenter-market-apps-v1-market-app/http';

type getMarketApp = {
  appId: string;
};

type queryMarketApp = {
  appIds: string[];
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class MarketAppService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-market-apps-v1-market-app/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-market-apps-v1-market-app/http');
  }

  public async get(payload: getMarketApp) {
    const response = await this.request<
      GetMarketAppRequest,
      GetMarketAppResponse
    >({
      methodName: 'getMarketApp',
      payload,
    });
    return new MarketApp(response.marketApp);
  }

  public async query(payload: queryMarketApp) {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryMarketApp');
    }
    const response = await this.request<
      QueryMarketAppRequest,
      QueryMarketAppResponse
    >({
      methodName: 'queryMarketApp',
      payload: {
        query: {
          paging: { limit: 100 },
          filter: {
            appId: payload.appIds,
          },
        },
      },
    });
    return new MarketApps(response.marketApps ?? []);
  }
}
