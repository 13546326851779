import React from 'react';
import s from './app-page-ctas.scss';
import { Box, type ButtonSize } from '@wix/design-system';
import type {
  ManagedApp,
  AppBadge,
  SupportedAppStatuses,
} from '@wix/app-market-services';
import {
  AppNotificationType,
  type AppNotification,
} from '../app-page-sections/notifications/types';
import { InstallAppButton } from './install-app-button';
import { OpenAppButton } from './open-app-button';
import {
  useTranslation,
  type ITranslationContext,
} from '../../../contexts/translations-context';
import { MobileInstallationBlockedModal } from './mobile-installation-blocked-modal';
import { UpgradeAppButton } from './upgrade-app-button';

export interface AppPageCTAsProps {
  managedApp?: ManagedApp;
  status?: SupportedAppStatuses;
  app: {
    id: string;
    instanceId?: string;
    name: string;
    isUpgradable: boolean;
    isAppWithBenefits: boolean;
    isInstalled: boolean;
    isBuiltIn: boolean;
    appBadges: AppBadge[];
    notification: AppNotification | null;
    gTag?: string;
  };
  size?: ButtonSize;
  dataHook: string;
  reverseOrder?: boolean;
}

export function AppPageCTAs({
  managedApp,
  app,
  size = 'large',
  dataHook,
  reverseOrder = false,
  status = 'PUBLISHED',
}: AppPageCTAsProps) {
  const { t } = useTranslation();

  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);

  const sharedButtonProps = {
    origin: dataHook,
    isDisabled: isCtaDisabled(app.notification?.type),
    tooltipContent: getCtaTooltipContent(app.notification, t),
    size,
    openMobileInstallationBlockedModal: () => setIsMobileModalOpen(true),
  };

  return (
    <>
      {isMobileModalOpen && (
        <MobileInstallationBlockedModal
          app={{
            id: app.id,
            name: app.name,
          }}
          onClose={() => setIsMobileModalOpen(false)}
        />
      )}
      <div data-hook={dataHook}>
        {app.isInstalled || app.isBuiltIn ? (
          <Box
            className={reverseOrder ? s.reverseOrder : ''}
            gap="6px"
            flexWrap="wrap"
          >
            {app.isUpgradable && (
              <UpgradeAppButton
                app={{
                  id: app.id,
                  instanceId: app.instanceId,
                  appBadges: app.appBadges,
                }}
                size={size}
                origin={dataHook}
              />
            )}
            <OpenAppButton
              {...sharedButtonProps}
              priority={app.isUpgradable ? 'secondary' : 'primary'}
              app={{ id: app.id, isBuiltIn: app.isBuiltIn }}
              managedApp={managedApp}
            />
          </Box>
        ) : (
          <InstallAppButton
            {...sharedButtonProps}
            status={status}
            app={{
              id: app.id,
              isAppWithBenefits: app.isAppWithBenefits,
              appBadges: app.appBadges,
              gTag: app.gTag,
            }}
          />
        )}
      </div>
    </>
  );
}

function getCtaTooltipContent(
  notification: AppNotification | null,
  t: ITranslationContext['t'],
): string {
  if (notification?.type === AppNotificationType.EditorNotSupported) {
    return t('solution.page.isNotAvailable.tooltip', {
      editorType:
        notification.data.editorType === 'EDITORX'
          ? t('editor.x.brand.name')
          : t('wix.studio.brand.name'),
    });
  }

  if (notification?.type === AppNotificationType.DependenciesNotInstalled) {
    return notification.data.appDependencies.length > 1
      ? t('solution.page.appDependenciesNotInstalled.tooltip')
      : t('solution.page.appDependencyNotInstalled.tooltip', {
          appRequirement: notification.data.appDependencies[0].name,
        });
  }

  if (notification?.type === AppNotificationType.DependencyPendingEditor) {
    return t('solution.page.appDependencyPending.tooltip', {
      appRequirement: notification.data.appDependency.name,
    });
  }

  if (notification?.type === AppNotificationType.PremiumSiteRequired) {
    return t('app.page.premium.required.notification.tooltip');
  }

  return '';
}

function isCtaDisabled(notificationType?: AppNotificationType): boolean {
  return Boolean(
    notificationType &&
      [
        AppNotificationType.EditorNotSupported,
        AppNotificationType.DependenciesNotInstalled,
        AppNotificationType.DependencyPendingEditor,
        AppNotificationType.PremiumSiteRequired,
      ].includes(notificationType),
  );
}
