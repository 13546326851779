import type { AppGroupSection, Services } from '@wix/app-market-services';
import { getAppGroupSectionsBySubCategoriesIds } from './sections';

type Category = {
  slug: string;
  name: string;
  description: string;
  image: string;
  subCategoriesIds: string[];
};

export interface CategoryPageData {
  category: Category;
  appGroupSections: AppGroupSection[];
}

export async function getCategoryPageData({
  services,
  slug,
  languageCode,
}: {
  services: Services;
  slug: string;
  languageCode: string;
}): Promise<CategoryPageData> {
  const tags = await services.queryTag({
    slugs: [slug],
    languageCode,
    isHidden: false,
  });
  const category = tags.findBySlug(slug);
  if (category.id === '') {
    throw new Error(
      `Category with slug ${slug} not found, languageCode: ${languageCode}`,
    );
  }

  const appGroupSections = category.subCategoriesIds.length
    ? await getAppGroupSectionsBySubCategoriesIds({
        services,
        subCategoriesIds: category.subCategoriesIds,
      })
    : [];

  return {
    category: {
      slug: category.slug,
      name: category.name,
      description: category.description,
      image: category.image,
      subCategoriesIds: category.subCategoriesIds,
    },
    appGroupSections,
  };
}
