import { ActivationStatus } from '@wix/ambassador-devcenter-v1-share-url/types';
import type { ShareUrl as ShareUrlType } from '@wix/ambassador-devcenter-v1-share-url/types';

export class ShareUrl {
  constructor(private readonly shareUrl: ShareUrlType | undefined) {}

  get appId(): string {
    return this.shareUrl?.appId ?? '';
  }

  get activisionStatus(): 'ENABLED' | 'DISABLED' {
    return this.shareUrl?.activationStatus ?? ActivationStatus.DISABLED;
  }
}
