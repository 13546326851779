import { RoutePath } from '@wix/app-market-core';
import type { IRoute, Router as IRouter } from '@wix/app-market-core';
import { isSSR } from '@wix/app-market-services';

export class Router implements IRouter {
  constructor(
    private readonly setRoute?: (
      route: IRoute,
      options?: { replace?: boolean },
    ) => void,
  ) {}

  hasHistory() {
    return !isSSR() && window.history.length > 1;
  }

  hasHref() {
    return true;
  }

  navigateTo: IRouter['navigateTo'] = ({ path, payload }, options) => {
    switch (path) {
      case RoutePath.HOME:
        this.setRoute?.({ path, payload });
        break;
      case RoutePath.APP_PAGE:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.SUB_CATEGORY:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.CATEGORY:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.COLLECTION:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.SEARCH_RESULTS:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.DEVELOPER:
        this.setRoute?.({ path, payload }, options);
        break;
      case RoutePath.BACK: {
        window.history.back();
        break;
      }
      case RoutePath.WIX_OFFERING: {
        const { appId } = payload;
        const wixOfferingPageUrl = `https://manage.wix.com/dashboard/{metaSiteId}/wix-offering/app/${appId}`;
        const siteSelectorUrl = `https://manage.wix.com/account/site-selector?actionUrl=${encodeURIComponent(
          wixOfferingPageUrl,
        )}`;
        window.open(siteSelectorUrl);
        break;
      }
    }
  };
}
