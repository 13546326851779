import type Experiments from '@wix/wix-experiments';
import type { ManagedApps, Services, AppBadge } from '@wix/app-market-services';
import type { ISiteContext } from '../../../../models';
import { AppNotificationType, type AppNotification } from './types';
import { hasBenefitOrBundleBadge } from '../../has-benefit-or-bundle-badge';

export async function getAppNotification({
  services,
  managedApps,
  app,
  siteContext,
  experiments,
}: {
  services: Services;
  managedApps?: ManagedApps;
  app: {
    id: string;
    name: string;
    isPremiumSiteRequired: boolean;
    dependencies: {
      id: string;
      slug: string;
      name: string;
      isPublished: boolean;
    }[];
    supportedInEditorX: boolean;
    supportedInStudio: boolean;
    badges: AppBadge[];
  };
  siteContext: ISiteContext;
  experiments: Experiments;
}): Promise<AppNotification | null> {
  const isEnableHeadlessAppNotification = experiments?.enabled(
    'specs.marketplace.headless-app-notification',
  );
  if (
    isEnableHeadlessAppNotification &&
    (await isShowHeadlessAppNote({ managedApps, services, appId: app.id }))
  ) {
    return {
      type: AppNotificationType.HeadlessAppInstalled,
      data: {
        appName: app.name,
      },
    };
  }

  if (
    (siteContext?.editorType === 'STUDIO' && !app.supportedInStudio) ||
    (siteContext?.editorType === 'EDITORX' && !app.supportedInEditorX)
  ) {
    return {
      type: AppNotificationType.EditorNotSupported,
      data: {
        appName: app.name,
        editorType: siteContext?.editorType,
      },
    };
  }

  if (
    app.isPremiumSiteRequired &&
    !siteContext.isPremium &&
    siteContext.metaSiteId
  ) {
    return {
      type: AppNotificationType.PremiumSiteRequired,
      data: {
        app: {
          id: app.id,
          name: app.name,
        },
      },
    };
  }

  const managedApp = managedApps?.findByAppId?.(app.id);
  const appDashboard = managedApp?.getAppDashboard?.();
  if (managedApp?.isAppPendingConfiguration?.() && appDashboard) {
    return {
      type: AppNotificationType.SetupIncomplete,
      data: {
        appId: app.id,
        dashboardUrl: appDashboard.url,
        isExternalDashboard: appDashboard.isExternal,
      },
    };
  }

  const publishedAppDependencies = app.dependencies.filter(
    (appDependency) => appDependency.isPublished,
  );
  if (
    managedApps &&
    publishedAppDependencies.length > 0 &&
    app.dependencies.every(
      (appDependency) => !managedApps.isAppInstalled(appDependency.id),
    )
  ) {
    return {
      type: AppNotificationType.DependenciesNotInstalled,
      data: {
        app,
        appDependencies: publishedAppDependencies,
      },
    };
  }

  const pendingEditorAppDependency = app.dependencies.find((appDependency) => {
    return isAppDependencyPendingEditor({
      managedApps,
      appDependency,
    });
  });

  if (
    pendingEditorAppDependency &&
    !appHasInstalledDependencyNotPendingEditor({
      appDependencies: app.dependencies,
      managedApps,
    })
  ) {
    return {
      type: AppNotificationType.DependencyPendingEditor,
      data: {
        app,
        appDependency: pendingEditorAppDependency,
      },
    };
  }

  if (
    hasBenefitOrBundleBadge({
      experiments,
      appBadges: app.badges,
    })
  ) {
    return {
      type: AppNotificationType.AppBenefit,
      data: {
        appName: app.name,
      },
    };
  }

  return null;
}

function appHasInstalledDependencyNotPendingEditor({
  appDependencies,
  managedApps,
}: {
  appDependencies: {
    id: string;
    slug: string;
    name: string;
  }[];
  managedApps: ManagedApps | undefined;
}): boolean {
  return appDependencies.some((appDependency) => {
    return Boolean(
      managedApps &&
        managedApps.isAppInstalled(appDependency.id) &&
        !managedApps.findByAppId(appDependency.id).isAppPendingEditor(),
    );
  });
}

async function isShowHeadlessAppNote({
  services,
  appId,
  managedApps,
}: {
  services: Services;
  appId: string;
  managedApps: ManagedApps | undefined;
}): Promise<boolean> {
  if (isHeadlessAppInstalled({ managedApps })) {
    const [isAppABusinessSolution, isAppHaveEditorComponent] =
      await Promise.all([
        appIsABusinessSolution({ services, appId }),
        appHaveEditorComponent({ services, appId }),
      ]);
    return !isAppABusinessSolution && isAppHaveEditorComponent;
  }

  return false;
}

function isHeadlessAppInstalled({
  managedApps,
}: {
  managedApps: ManagedApps | undefined;
}): boolean {
  return Boolean(
    managedApps &&
      managedApps.isAppInstalled('78640cbb-be47-45f8-b6de-8ed97287872c'),
  );
}

async function appHaveEditorComponent({
  services,
  appId,
}: {
  services: Services;
  appId: string;
}): Promise<boolean> {
  const appsComponents = await services.getAppComponentsByAppIds({
    appId,
    componentTypes: [
      'EMBEDDED_SCRIPT',
      'WIDGET',
      'STUDIO_WIDGET',
      'WIDGET_OUT_OF_IFRAME',
      'PAGE',
    ],
  });
  const appComponents = appsComponents.findByAppId(appId);
  return Boolean(appComponents.components.length);
}

async function appIsABusinessSolution({
  services,
  appId,
}: {
  services: Services;
  appId: string;
}): Promise<boolean> {
  const { appIds } = await services.queryTagApp({
    appIds: [appId],
    tagIds: ['db25f881-7bf3-4158-94da-ffae263f1dab'],
  });
  return Boolean(appIds.length);
}

function isAppDependencyPendingEditor({
  managedApps,
  appDependency,
}: {
  managedApps: ManagedApps | undefined;
  appDependency: {
    id: string;
    slug: string;
    name: string;
  };
}): boolean {
  return Boolean(
    managedApps &&
      managedApps.findByAppId(appDependency.id).isAppPendingEditor(),
  );
}
