import React from 'react';
import s from './grid.module.scss';
import { getGridColumnsCount } from './grid.utils';

export interface GridProps {
  dataHook?: string;
  columns: 1 | 2 | 3 | 4;
  minColumns?: 1 | 2 | 3 | 4;
  rows?: number;
  children?: React.ReactNode;
  finalColumnsCount?: (finalColumns: number) => void;
}

export function Grid({
  dataHook,
  columns: maxColumns,
  minColumns = 1,
  rows: maxRows,
  finalColumnsCount,
  children,
}: GridProps) {
  const gridRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (gridRef.current) {
      const columnsCount = getGridColumnsCount(gridRef.current);
      finalColumnsCount?.(columnsCount);
    }
  }, [gridRef.current]);

  return (
    <div
      ref={gridRef}
      data-hook={dataHook}
      className={s.gridContainer}
      style={
        {
          '--grid-min-columns': minColumns,
          '--grid-max-columns': maxColumns,
          ...(maxRows && { '--grid-max-rows': maxRows }),
          ...(maxColumns === 1 && {
            '--grid-gap': '18px',
            '--grid-item-min-width': '250px',
          }),
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
}
