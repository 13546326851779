import type {
  Services,
  IAppsPricingPlans,
  SupportedAppStatuses,
} from '@wix/app-market-services';
import { AppPricingPlans } from '@wix/app-market-services';

export async function getAppPricingPlans({
  services,
  appId,
  languageCode,
  status,
  usePlansV2,
}: {
  services: Services;
  appId: string;
  languageCode: string;
  status: SupportedAppStatuses;
  usePlansV2: boolean;
}): Promise<IAppsPricingPlans> {
  if (status === 'DRAFT') {
    const pricingPlans = usePlansV2
      ? (
          await services.plans.query({
            appIds: [appId],
            status,
          })
        ).findByAppId(appId)
      : await services.getPricingModel({
          appId,
          languageCode,
          isPublished: false,
        });
    const appPricingPlans = new AppPricingPlans(
      undefined,
      pricingPlans,
      status,
    ).toJSON();
    return appPricingPlans;
  } else {
    const [appsPlans, pricingPlans] = await Promise.all([
      services.appPlans.list({
        appIds: [appId],
      }),
      usePlansV2
        ? (
            await services.plans.query({
              appIds: [appId],
              status,
            })
          ).findByAppId(appId)
        : services.getPricingModel({
            appId,
            languageCode,
            isPublished: status === 'PUBLISHED',
          }),
    ]);

    const appPricingPlans = new AppPricingPlans(
      appsPlans.findByAppId(appId),
      pricingPlans,
      status,
    ).toJSON();

    return appPricingPlans;
  }
}
