import type RpcModule from '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/rpc';
import type HttpModule from '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/http';
import { RequestService } from '../request-service';
import type {
  QueryGoogleAnalyticsConfigsRequest,
  QueryGoogleAnalyticsConfigsResponse,
  CreateGoogleAnalyticsConfigRequest,
  CreateGoogleAnalyticsConfigResponse,
  UpdateGoogleAnalyticsConfigRequest,
  UpdateGoogleAnalyticsConfigResponse,
  GetGoogleAnalyticsConfigRequest,
  GetGoogleAnalyticsConfigResponse,
  DeleteGoogleAnalyticsConfigRequest,
  DeleteGoogleAnalyticsConfigResponse,
} from '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/types';
import { GoogleAnalyticsConfigs } from './google-analytics-configs';
import { GoogleAnalyticsConfig } from './google-analytics-config';

type getGoogleAnalyticsConfig = {
  googleAnalyticsConfigId: string;
};

type queryGoogleAnalyticsConfig = {
  id: string;
};

type createGoogleAnalyticsConfig = {
  id: string;
  tagId: string;
  apiKey?: string;
};

type updateGoogleAnalyticsConfig = {
  id: string;
  tagId: string;
  apiKey: string;
  revision: string;
};

type deleteGoogleAnalyticsConfig = {
  googleAnalyticsConfigId: string;
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class GoogleAnalyticsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import(
      '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/rpc'
    );
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import(
      '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/http'
    );
  }

  public async get(payload: getGoogleAnalyticsConfig) {
    if (!payload.googleAnalyticsConfigId) {
      throw new Error(
        'googleAnalyticsConfigId is empty in getGoogleAnalyticsConfig',
      );
    }
    const response = await this.request<
      GetGoogleAnalyticsConfigRequest,
      GetGoogleAnalyticsConfigResponse
    >({
      methodName: 'getGoogleAnalyticsConfig',
      payload: {
        googleAnalyticsConfigId: payload.googleAnalyticsConfigId,
      },
    });
    return new GoogleAnalyticsConfig(
      response.googleAnalyticsConfig || undefined,
    );
  }

  public async query(payload: queryGoogleAnalyticsConfig) {
    if (!payload.id) {
      throw new Error('id is empty in queryGoogleAnalyticsConfig');
    }
    const response = await this.request<
      QueryGoogleAnalyticsConfigsRequest,
      QueryGoogleAnalyticsConfigsResponse
    >({
      methodName: 'queryGoogleAnalyticsConfigs',
      payload: {
        query: {
          filter: {
            id: payload.id,
          },
        },
      },
    });
    return new GoogleAnalyticsConfigs(response.googleAnalyticsConfigs || []);
  }

  public async create(payload: createGoogleAnalyticsConfig) {
    if (!payload.id) {
      throw new Error('id is empty in createGoogleAnalyticsConfig');
    }
    if (!payload.tagId) {
      throw new Error('tagId is empty in createGoogleAnalyticsConfig');
    }
    const response = await this.request<
      CreateGoogleAnalyticsConfigRequest,
      CreateGoogleAnalyticsConfigResponse
    >({
      methodName: 'createGoogleAnalyticsConfig',
      payload: {
        googleAnalyticsConfig: {
          id: payload.id,
          tagId: payload.tagId,
          ...(payload.apiKey && { apiKey: payload.apiKey }),
        },
      },
    });
    return new GoogleAnalyticsConfig(
      response.googleAnalyticsConfig || undefined,
    );
  }

  public async update(payload: updateGoogleAnalyticsConfig) {
    if (!payload.id) {
      throw new Error('id is empty in updateGoogleAnalyticsConfig');
    }
    const response = await this.request<
      UpdateGoogleAnalyticsConfigRequest,
      UpdateGoogleAnalyticsConfigResponse
    >({
      methodName: 'updateGoogleAnalyticsConfig',
      payload: {
        googleAnalyticsConfig: {
          id: payload.id,
          tagId: payload.tagId,
          apiKey: payload.apiKey,
          revision: payload.revision,
        },
        fieldMask: [
          payload.tagId.length ? 'tagId' : '',
          payload.apiKey.length ? 'apiKey' : '',
        ],
      },
    });
    return new GoogleAnalyticsConfig(
      response.googleAnalyticsConfig || undefined,
    );
  }

  public async delete(payload: deleteGoogleAnalyticsConfig) {
    if (!payload.googleAnalyticsConfigId) {
      throw new Error(
        'googleAnalyticsConfigId is empty in deleteGoogleAnalyticsConfig',
      );
    }
    await this.request<
      DeleteGoogleAnalyticsConfigRequest,
      DeleteGoogleAnalyticsConfigResponse
    >({
      methodName: 'deleteGoogleAnalyticsConfig',
      payload: {
        googleAnalyticsConfigId: payload.googleAnalyticsConfigId,
      },
    });
  }
}
