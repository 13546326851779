import React from 'react';
import { Badge } from '.';
import { useEssentials } from '../../contexts/essentials-context';
import { Box, Text } from '@wix/design-system';

interface DeveloperSaleBadgeProps {
  showBorder?: boolean;
  size?: 'tiny' | 'small' | 'medium';
  amount: string;
}

export function DeveloperSaleBadge({
  showBorder,
  size = 'small',
  amount,
}: DeveloperSaleBadgeProps) {
  const { t } = useEssentials();

  return (
    <Badge
      skin="standard"
      type="outlined"
      dataHook="dev-sale-badge"
      uppercase={false}
      showBorder={showBorder}
      size={size}
      tooltipContent={
        <Text size="small" weight="thin" light>
          {t('developer.sale.badge.tooltip', { amount })}
        </Text>
      }
    >
      <Box color="#116DFF">-{amount}%</Box>
    </Badge>
  );
}
