import { RequestService } from '../request-service';
import type {
  GetShareUrlRequest,
  GetShareUrlResponse,
} from '@wix/ambassador-devcenter-v1-share-url/types';
import type RpcModule from '@wix/ambassador-devcenter-v1-share-url/rpc';
import type HttpModule from '@wix/ambassador-devcenter-v1-share-url/http';
import { ShareUrl } from './dev-center-share-url.entity';

export type GetShareUrlLink = {
  shareUrlId: string;
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class GetShareUrlLinkService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-v1-share-url/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-v1-share-url/http');
  }

  public async get(payload: GetShareUrlLink) {
    const response = await this.request<
      GetShareUrlRequest,
      GetShareUrlResponse
    >({
      methodName: 'getShareUrl',
      payload,
    });
    return new ShareUrl(response.shareUrl);
  }
}
