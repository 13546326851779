import { ComponentMonitor } from './component-monitor';
import type { AnyObject, MarketWatchConfigs } from './types';
import { MarketWatchPlatform } from './types';
import {
  PanoramaPlatform,
  panoramaClientFactory,
  createGlobalConfig,
} from '@wix/panorama-client-web';

type PanoramaClientFactory = typeof panoramaClientFactory;

export const globalConfig = createGlobalConfig();

export class MarketWatch {
  private globalParams: AnyObject = {};
  private componentMonitors: Map<string, ComponentMonitor> = new Map();
  private panoramaFactory: ReturnType<PanoramaClientFactory>;

  constructor(configs: MarketWatchConfigs) {
    const isSSR = typeof window === 'undefined';

    const {
      platform: marketWatchPlatform,
      fullArtifactId,
      artifactVersion,
      sentryDsn,
    } = configs;

    this.panoramaFactory = panoramaClientFactory({
      baseParams: {
        platform: this.mapToPanoramaPlatform(marketWatchPlatform),
        fullArtifactId,
        artifactVersion,
      },
      pluginParams: {
        ...(!isSSR && {
          sentry: window.Sentry,
          sentryDsn,
          sentryMain: marketWatchPlatform === MarketWatchPlatform.Standalone,
        }),
        useBatch: !isSSR,
      },
      ...(isSSR && {
        reporterOptions: {
          fetchFn: fetch,
        },
      }),
    }).withGlobalConfig(globalConfig);
  }

  updateGlobalParams(params: AnyObject): void {
    this.globalParams = {
      ...this.globalParams,
      ...params,
    };
  }

  monitor(componentId: string, uuid?: string): ComponentMonitor {
    if (!this.isValidComponentId(componentId)) {
      throw new Error(
        `Invalid componentId: ${componentId}, componentId should be in kebab-case`,
      );
    }

    const monitorId = `${componentId}-${uuid}`;
    const componentMonitor = this.componentMonitors.get(monitorId);
    if (componentMonitor) {
      return componentMonitor;
    }

    const newComponentMonitor = new ComponentMonitor(
      componentId,
      this.panoramaFactory,
      this.globalParams,
    );
    this.componentMonitors.set(monitorId, newComponentMonitor);
    return newComponentMonitor;
  }

  private isValidComponentId(componentId: string): boolean {
    const lowercaseKebabCaseRegex = /^[a-z]+(-[a-z]+)*$/;
    return lowercaseKebabCaseRegex.test(componentId);
  }

  private mapToPanoramaPlatform(
    platform: MarketWatchPlatform,
  ): PanoramaPlatform {
    switch (platform) {
      case MarketWatchPlatform.BusinessManager:
        return PanoramaPlatform.BusinessManager;
      case MarketWatchPlatform.Standalone:
        return PanoramaPlatform.Standalone;
      case MarketWatchPlatform.Editor:
        return PanoramaPlatform.Editor;
      default:
        throw new Error(`Unsupported platform: ${platform}`);
    }
  }
}
