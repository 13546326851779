import type { GoogleAnalyticsConfig as GoogleAnalyticsConfigType } from '@wix/ambassador-appmarket-analytics-v1-google-analytics-config/types';

export class GoogleAnalyticsConfig {
  constructor(
    private readonly googleAnalyticsConfig:
      | GoogleAnalyticsConfigType
      | undefined,
  ) {}

  get googleTagId(): string {
    return this.googleAnalyticsConfig?.tagId ?? '';
  }

  get googleApiKey(): string {
    return this.googleAnalyticsConfig?.apiKey ?? '';
  }
  get revision(): string {
    return this.googleAnalyticsConfig?.revision ?? '';
  }

  toJSON() {
    return this.googleAnalyticsConfig as GoogleAnalyticsConfigType;
  }
}
