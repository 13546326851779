import React from 'react';
import { Badge } from '.';
import { useEssentials } from '../../contexts/essentials-context';
import { Box, Text } from '@wix/design-system';

interface BetaBadgeProps {
  showBorder?: boolean;
  appName: string;
  size?: 'tiny' | 'small' | 'medium';
}

export function BetaBadge({
  showBorder,
  appName,
  size = 'small',
}: BetaBadgeProps) {
  const { t } = useEssentials();

  return (
    <Badge
      skin="neutralStandard"
      type="outlined"
      dataHook="beta-badge"
      uppercase={false}
      showBorder={showBorder}
      size={size}
      tooltipContent={
        <Box direction="vertical" gap="6px">
          <Text size="small" weight="thin" light>
            {t('beta.badge.tooltip', { appName })}
          </Text>
        </Box>
      }
    >
      <Box marginTop="2px">{t('beta.badge')}</Box>
    </Badge>
  );
}
