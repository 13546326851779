import type { CategoryCarouselProps } from '@wix/app-market-components';
import type { CategoryCarouselSection } from '@wix/app-market-services';
import type { AppMarketPage } from '../enums';
import { useEssentials } from '../contexts/essentials-context';
import {
  appMarketWidgetsClickAction,
  appMarketWidgetsNavigationClick,
  categoryClick,
} from '@wix/bi-logger-app-market-data/v2';
import {
  getAppMarketWidgetsNavigationClickBiData,
  getCategoryClickBiData,
  getWidgetClickBiData,
} from '../bi-services/events-data-parser';
import { useRouterContext, useSiteContext } from '../contexts';
import type { RoutePayloadMap } from '../models';
import { Route, RoutePath } from '../models';

/**
 * This hook is responsible for mapping the category carousel section data to a shared props of the CategoryCarousel component.
 * Different pages can use this hook to easily render the CategoryCarousel component by using the shared props and add
 * their own specific props.
 * @returns A function that maps the category carousel section data to a shared props of the CategoryCarousel component.
 */
export function useCategoryCarouselSharedPropsMapper(): (
  categoryCarousel: CategoryCarouselSection,
  page: AppMarketPage,
) => CategoryCarouselProps {
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const { baseURL } = useSiteContext();

  return (
    categoryCarousel: CategoryCarouselSection,
    page: AppMarketPage,
  ): CategoryCarouselProps => {
    // TODO: take it from serverless after BEDs will add it to market-sections service
    const title = 'Explore by Category';
    const tagTypeClicked = 'category';
    const tagName = 'explore_by_category';
    const onCategoryClick = ({ slug }: { slug: string }) => {
      biLogger.report(
        categoryClick(getCategoryClickBiData({ slug, tagTypeClicked })),
      );
      biLogger.report(
        appMarketWidgetsClickAction(
          getWidgetClickBiData({
            pageName: page,
            tagName,
            tagType: page,
            tagTypeClicked,
            tagSlag: slug,
          }),
        ),
      );
      router.navigateTo({
        path: RoutePath.CATEGORY,
        payload: {
          slug,
        },
      });
    };

    const onChange = (currentSlide: number, nextSlide: number) =>
      biLogger.report(
        appMarketWidgetsNavigationClick(
          getAppMarketWidgetsNavigationClickBiData({
            currentSlide,
            nextSlide,
            tagName,
            tagType: page,
          }),
        ),
      );

    function getHrefByCategory(payload: RoutePayloadMap[RoutePath.CATEGORY]) {
      const hasHref = router.hasHref();
      if (!hasHref) {
        return undefined;
      }
      const route = new Route({
        path: RoutePath.CATEGORY,
        payload,
        baseURL,
      });
      return route.toHref();
    }

    return {
      categories: categoryCarousel.categories,
      onCategoryClick,
      title,
      onChange,
      getHrefByCategory,
    };
  };
}
