import { RequestService } from '../request-service';
import { Apps } from './apps';
import type {
  QueryAppRequest,
  QueryAppResponse,
} from '@wix/ambassador-devcenter-apps-v1-app/types';
import type RpcModule from '@wix/ambassador-devcenter-apps-v1-app/rpc';
import type HttpModule from '@wix/ambassador-devcenter-apps-v1-app/http';

export type QueryApp = {
  appIds?: string[];
  slugs?: string[];
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class AppsService extends RequestService<RpcModuleType, HttpModuleType> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-apps-v1-app/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-apps-v1-app/http');
  }

  public async query(payload: QueryApp) {
    const { appIds: id, slugs: slug } = payload;
    const response = await this.request<QueryAppRequest, QueryAppResponse>({
      methodName: 'queryApp',
      payload: {
        query: {
          filter: {
            ...(id ? { id } : {}),
            ...(slug ? { slug } : {}),
          },
        },
      },
    });
    return new Apps(response.apps || []);
  }
}
