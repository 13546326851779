import { ESidebarItemTypes } from '../sidebar-constants';
import {
  Box,
  SidebarDividerNext,
  SidebarItemNext,
  SidebarNext,
  SidebarSubMenuNext,
  SidebarTitleItemNext,
} from '@wix/design-system';
import s from '../sidebar.scss';
import { SidebarHeader } from './sidebar-header';
import { SidebarFooter } from './sidebar-footer';
import React, { useMemo } from 'react';
import type {
  EventType,
  GetSidebarItemProps,
  SidebarItemsComponentType,
  SidebarItem,
  OnLinkItemClick,
  OnSubItemLinkClick,
  OnQuickNavOpen,
} from '../types';
import { useSiteContext } from '../../../contexts';

interface SidebarComponentProps {
  sidebarItems: SidebarItem[];
  selectedItemKey: string;
  isMetaSiteId: boolean;
  pendingAppsCount: number;
  shouldShowFooter: boolean;
  shouldShowHeader: boolean;
  onItemClick: OnLinkItemClick;
  onQuickNavOpen: OnQuickNavOpen;
  onSubItemClick: OnSubItemLinkClick;
  onFooterClick(): void;
  onLoad(): void;
}

export function SidebarComponent({
  sidebarItems,
  isMetaSiteId,
  selectedItemKey,
  pendingAppsCount,
  shouldShowFooter,
  shouldShowHeader,
  onItemClick,
  onQuickNavOpen,
  onSubItemClick,
  onFooterClick,
  onLoad,
}: SidebarComponentProps) {
  const { languageCode } = useSiteContext();
  useMemo(() => onLoad(), [languageCode]);
  return (
    <Box zIndex={10} className={s.sidebar}>
      <SidebarNext
        skin="light"
        selectedKey={selectedItemKey}
        isLoading={false}
        header={shouldShowHeader && <SidebarHeader />}
        footer={
          shouldShowFooter && (
            <SidebarFooter
              pendingAppsCount={pendingAppsCount}
              onClick={() => onFooterClick()}
            />
          )
        }
      >
        {sidebarItems.map((sidebarItem, index) => {
          return getSidebarItem({
            sidebarItem,
            index,
            isMetaSiteId,
            onItemClick,
            onSubItemClick,
            onQuickNavOpen,
          });
        })}
      </SidebarNext>
    </Box>
  );
}

function getSidebarItem({
  sidebarItem,
  index,
  isMetaSiteId,
  onItemClick,
  onSubItemClick,
  onQuickNavOpen,
}: GetSidebarItemProps): SidebarItemsComponentType {
  switch (sidebarItem.type) {
    case ESidebarItemTypes.DIVIDER:
      return <SidebarDividerNext key={`divider-${index}`} />;

    case ESidebarItemTypes.TITLE:
      return (
        <SidebarTitleItemNext key={sidebarItem.label}>
          {sidebarItem.label}
        </SidebarTitleItemNext>
      );

    case ESidebarItemTypes.LINK:
      return (
        <SidebarItemNext
          dataHook={`sidebar-collection-item-${sidebarItem.key}`}
          key={sidebarItem.key}
          itemKey={sidebarItem.key}
          href={sidebarItem.href}
          className={sidebarItem.highlighted ? s.highlightedItem : ''}
          /* support href on SA, and prevent-default navigation in other containers */
          as={isMetaSiteId ? 'button' : 'a'}
          onClick={(event: EventType) => {
            event.preventDefault();
            event.stopPropagation();
            onItemClick(sidebarItem, index);
          }}
        >
          {sidebarItem.label}
        </SidebarItemNext>
      );

    case ESidebarItemTypes.SUB_MENU:
      return (
        <SidebarSubMenuNext
          dataHook={`sidebar-category-item-${sidebarItem.key}`}
          key={sidebarItem.key}
          itemKey={sidebarItem.key}
          title={sidebarItem.label}
          href={sidebarItem.href}
          /* support href on SA, and prevent-default navigation in other containers */
          as={isMetaSiteId ? 'button' : 'a'}
          onClick={(event: EventType) => {
            event.preventDefault();
            event.stopPropagation();
            onItemClick(sidebarItem, index);
          }}
          onQuickNavOpen={() => onQuickNavOpen(sidebarItem, index)}
        >
          {sidebarItem.links.map((link) => (
            <SidebarItemNext
              dataHook={`sidebar-subcategory-item-${link.key}`}
              key={link.key}
              itemKey={link.key}
              href={link.href}
              /* support href on SA, and prevent-default navigation in other containers */
              as={isMetaSiteId ? 'button' : 'a'}
              onClick={(event: EventType) => {
                event.preventDefault();
                event.stopPropagation();
                onSubItemClick(link, index, sidebarItem);
              }}
            >
              {link.label}
            </SidebarItemNext>
          ))}
        </SidebarSubMenuNext>
      );
  }
}
