import { useEssentials, useServices } from '../../../../contexts';
import {
  appMarketAddEditAppReviewClick,
  appMarketAppPageClick,
  appMarketAppPageReadMoreReviewsClick,
  appMarketCancelAppReviewClick,
  appMarketLikeUnlikeAppReview,
  appMarketPostAppReview,
  appMarketReviewAppNotInstalledAlert,
  appMarketReviewImpression,
  appMarketSortReviews,
} from '@wix/bi-logger-app-market-data/v2';
import type { Opinion } from '@wix/app-market-components';
import type { ReviewType as Review } from '@wix/app-market-services';
import {
  createOpinion,
  deleteOpinion,
} from '@wix/ambassador-devcenter-v1-review/http';

interface ReviewsActions {
  onReFetchReviews: (
    appId: string,
    sortMethod: string,
    limit?: number,
  ) => Promise<{ reviews: Review[] }>;
  onLike: (appId: string, reviewId: string) => Promise<{ opinion?: Opinion }>;
  onUnlike: (
    appId: string,
    reviewId: string,
    opinionId: string,
  ) => Promise<{ opinion?: Opinion }>;
  onReviewsLoad: (
    appId: string,
    sortMethod: string,
    offset: number,
  ) => Promise<{ reviews: Review[] }>;
  onUserAction: (appId: string, isEligibleForReview: boolean) => void;
  onReviewLoad: (appId: string, review: Review, index: number) => void;
  onSubmit(
    appId: string,
    reviewData: Review,
  ): Promise<{
    userReview: Review;
  }>;
  onClose(appId: string): void;
  onSortChange(appId: string, sortMethod: string): void;
  onReadMoreOrLessClick(appId: string, clickType: string): void;
  onContactSupport(
    appId: string,
    isWixApp: boolean,
    location: string,
    supportMails: string,
  ): void;
}

export const useReviewsActions = (): ReviewsActions => {
  const { httpClient, biLogger } = useEssentials();
  const services = useServices();
  function getBiSortValue(sort: string) {
    if (sort === 'publishedAt') {
      return 'most_recent';
    }
    if (sort === 'totalLikes') {
      return 'most_helpful';
    } else {
      return 'highest_rating';
    }
  }
  function sendSortReviewsChangeBi(appId: string, sortMethod: string) {
    biLogger.report(
      appMarketSortReviews({
        appId,
        value: getBiSortValue(sortMethod),
      }),
    );
  }

  function sendLikeOrUnlikeBi(appId: string, helpfulClick: string) {
    biLogger.report(
      appMarketLikeUnlikeAppReview({
        appId,
        helpful_click: helpfulClick,
      }),
    );
  }

  function sendReadMoreReviewsClickBi(appId: string, clickType: string) {
    biLogger.report(
      appMarketAppPageReadMoreReviewsClick({
        app_id: appId,
        tag_name: 'app_page',
        click_type: clickType,
      }),
    );
  }

  function sendAppNotInstalledAlertBi(appId: string) {
    biLogger.report(
      appMarketReviewAppNotInstalledAlert({
        app_id: appId,
      }),
    );
  }

  function sendReviewModalOpenBi(actionType: string, appId: string) {
    biLogger.report(
      appMarketAddEditAppReviewClick({
        actionType,
        appId,
        post_type: 'new_review',
      }),
    );
  }

  function sendReviewImpressionBi(
    appId: string,
    review: Review,
    index: number,
  ) {
    biLogger.report(
      appMarketReviewImpression({
        appId,
        review_body: review?.description,
        reviewsScore: review?.rate,
        review_index: index,
        dev_comment: !!review?.replies[0],
      }),
    );
  }

  function sendPostReviewBi(
    appId: string,
    reviewData: Partial<Review>,
    action: string,
  ) {
    biLogger
      .report(
        appMarketPostAppReview({
          appId,
          appScore: reviewData.rate?.toString(),
          review_title: reviewData.title,
          has_description: Boolean(reviewData.description),
          review_body: reviewData.description,
          actionType: reviewData?.id ? 'edit_review' : 'add_review',
          post_type: action,
          tag_type: 'app_page',
        }),
      )
      .catch((error) => console.log(error));
  }

  function sendCloseReviewModalBi(appId: string) {
    biLogger
      .report(
        appMarketCancelAppReviewClick({
          appId,
          tag_type: 'app_page',
        }),
      )
      .catch((error) => console.log(error));
  }

  function sendAppPageClick(appId: string, location: string, value: string) {
    biLogger.report(
      appMarketAppPageClick({
        app_id: appId,
        cta: 'contact_support',
        location,
        value,
      }),
    );
  }

  async function onReFetchReviews(
    appId: string,
    sortMethod: string,
    limit?: number,
  ) {
    const reviews = await services.reviews.query({
      appIds: [appId],
      sortMethod,
      limit: limit ?? 5,
      offset: 0,
    });

    return { reviews: reviews.toJSON() };
  }

  async function onLike(appId: string, reviewId: string) {
    const {
      data: { opinion },
    } = await httpClient.request(
      createOpinion({
        appId,
        reviewId,
      }),
    );
    sendLikeOrUnlikeBi(appId, 'like');
    return { opinion: { id: opinion?.id ?? '' } };
  }

  async function onUnlike(appId: string, reviewId: string, opinionId: string) {
    const {
      data: { opinion },
    } = await httpClient.request(
      deleteOpinion({
        appId,
        reviewId,
        opinionId,
      }),
    );
    sendLikeOrUnlikeBi(appId, 'unlike');

    return { opinion: { id: opinion?.id ?? '' } };
  }

  async function onReviewsLoad(
    appId: string,
    sortMethod: string,
    offset: number,
  ) {
    const reviews = await services.reviews.query({
      appIds: [appId],
      sortMethod,
      limit: 5,
      offset,
    });
    sendReadMoreReviewsClickBi(appId, 'reviews');

    return { reviews: reviews.toJSON() };
  }

  function onUserAction(appId: string, isEligibleForReview: boolean) {
    if (!isEligibleForReview) {
      sendAppNotInstalledAlertBi(appId);
    } else {
      sendReviewModalOpenBi('add review', appId);
    }
  }

  async function onSubmit(appId: string, reviewData: Review) {
    const { id, rate, title, description, revision } = reviewData;
    let userReview: Review;
    let action: string;
    if (id) {
      userReview = (
        await services.reviews.update({
          appId,
          reviewId: id,
          title: title ?? '',
          description,
          rating: rate,
          revision: revision ?? '0',
        })
      ).toJSON();
      action = 'edit review';
    } else {
      userReview = (
        await services.reviews.create({
          appId,
          title: title ?? '',
          description,
          rating: rate,
        })
      ).toJSON();
      action = 'new review';
    }
    sendPostReviewBi(appId, reviewData, action);
    return { userReview };
  }

  function onContactSupport(
    appId: string,
    isWixApp: boolean,
    location: string,
    supportMails: string,
  ) {
    if (isWixApp) {
      sendAppPageClick(appId, location, 'contact_costumer_care');
      window.open('https://www.wix.com/contact');
    } else {
      sendAppPageClick(appId, location, 'contact_support');
      window.location.href = `mailto:${supportMails}`;
    }
  }

  return {
    onReFetchReviews,
    onLike,
    onUnlike,
    onReviewsLoad,
    onUserAction,
    onReviewLoad: sendReviewImpressionBi,
    onReadMoreOrLessClick: sendReadMoreReviewsClickBi,
    onSubmit,
    onClose: sendCloseReviewModalBi,
    onSortChange: sendSortReviewsChangeBi,
    onContactSupport,
  };
};
