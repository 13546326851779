enum AppNotificationType {
  EditorNotSupported = 'EDITOR_NOT_SUPPORTED',
  SetupIncomplete = 'SETUP_INCOMPLETE',
  DependenciesNotInstalled = 'DEPENDENCIES_NOT_INSTALLED',
  DependencyPendingEditor = 'DEPENDENCY_PENDING_EDITOR',
  PremiumSiteRequired = 'PREMIUM_SITE_REQUIRED',
  HeadlessAppInstalled = 'HEADLESS_APP_INSTALLED',
  AppBenefit = 'APP_BENEFIT',
}

interface EditorNotSupportedNotificationData {
  appName: string;
  editorType: 'EDITORX' | 'STUDIO';
}

interface SetupIncompleteNotificationData {
  appId: string;
  dashboardUrl: string;
  isExternalDashboard: boolean;
}
interface DependenciesNotInstalledNotificationData {
  app: {
    id: string;
    name: string;
  };
  appDependencies: {
    id: string;
    name: string;
    slug: string;
  }[];
}
interface DependencyPendingEditorNotificationData {
  app: {
    id: string;
    name: string;
  };
  appDependency: {
    id: string;
    name: string;
  };
}
interface PremiumSiteRequiredNotificationData {
  app: {
    id: string;
    name: string;
  };
}
interface HeadlessAppInstalledNotificationData {
  appName: string;
}

interface AppBenefitNotificationData {
  appName: string;
}

interface AppNotificationDataMap {
  [AppNotificationType.EditorNotSupported]: EditorNotSupportedNotificationData;
  [AppNotificationType.SetupIncomplete]: SetupIncompleteNotificationData;
  [AppNotificationType.DependenciesNotInstalled]: DependenciesNotInstalledNotificationData;
  [AppNotificationType.DependencyPendingEditor]: DependencyPendingEditorNotificationData;
  [AppNotificationType.PremiumSiteRequired]: PremiumSiteRequiredNotificationData;
  [AppNotificationType.HeadlessAppInstalled]: HeadlessAppInstalledNotificationData;
  [AppNotificationType.AppBenefit]: AppBenefitNotificationData;
}

type SomeNotification<T extends AppNotificationType> = {
  type: T;
  data: AppNotificationDataMap[T];
};

type AppNotification =
  | SomeNotification<AppNotificationType.EditorNotSupported>
  | SomeNotification<AppNotificationType.SetupIncomplete>
  | SomeNotification<AppNotificationType.DependenciesNotInstalled>
  | SomeNotification<AppNotificationType.DependencyPendingEditor>
  | SomeNotification<AppNotificationType.PremiumSiteRequired>
  | SomeNotification<AppNotificationType.HeadlessAppInstalled>
  | SomeNotification<AppNotificationType.AppBenefit>;

export { AppNotification, AppNotificationType };
