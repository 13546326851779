import { RequestService } from '../request-service';
import type RpcModule from '@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc';
import type HttpModule from '@wix/ambassador-devcenter-appmarket-v1-tag-app/http';
import type {
  Paging,
  GetAppsByTagRequest,
  GetAppsByTagResponse,
} from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { IHttpClient } from '@wix/http-client';
import { TagApps } from './tag-apps';
import type { SortType } from './types';
import type { QueryAppsFilter } from '../../types';
import { AppBenefitsService } from '../app-benefits';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class TagAppsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  private readonly tagIds = {
    benefits: 'eab81c12-5916-4ec7-bb5b-a9bdcdfea4af',
  };
  private readonly appBenefitsService: AppBenefitsService;

  constructor(rpcClient?: AmbassadorBootstrap, httpClient?: IHttpClient) {
    super(rpcClient, httpClient);
    this.appBenefitsService = new AppBenefitsService(rpcClient, httpClient);
  }

  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-appmarket-v1-tag-app/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-appmarket-v1-tag-app/http');
  }

  public async getAppsByTag(payload: {
    tagId: string;
    sortTypes?: SortType[];
    paging?: Paging;
    filter?: QueryAppsFilter;
  }): Promise<TagApps> {
    const { tagId, sortTypes, paging, filter = {} } = payload;
    const response = await this.getAppsByTagExtended({
      request: {
        tagId,
        ...(sortTypes ? { sortTypes } : {}),
        ...(paging ? { paging } : {}),
        ...(filter?.isSale
          ? { filter: { saleType: { $ne: 'SALE_TYPE_UNSPECIFIED' } } }
          : {}),
      },
      includeDealerBenefits: !!filter?.includeDealerBenefits,
    });
    return new TagApps(
      response.tagApps || [],
      response.pagingMetadata || undefined,
    );
  }

  private async getAppsByTagExtended({
    request,
    includeDealerBenefits,
  }: {
    request: GetAppsByTagRequest;
    includeDealerBenefits: boolean;
  }): Promise<GetAppsByTagResponse> {
    if (request.tagId === this.tagIds.benefits) {
      const benefits = await this.appBenefitsService.get({
        includeDealerBenefits,
      });
      return {
        tagApps: benefits.map(({ appId }) => {
          return {
            appId,
            tagId: this.tagIds.benefits,
          };
        }),
        pagingMetadata: {
          total: benefits.length,
          hasNext: false,
        },
      };
    }
    return this.request<GetAppsByTagRequest, GetAppsByTagResponse>({
      methodName: 'getAppsByTag',
      payload: request,
    });
  }
}
