import type { ISiteContext } from '../../models';
import { getReviewsSectionData } from './app-page-sections/reviews/reviews-api';
import type { getReviewsSectionResponse } from './app-page-sections/reviews/reviews-api';
import type { Plan } from '@wix/app-market-components';
import type {
  CurrencySettings,
  TaxDetails,
  Benefits,
  Media,
  MarketListing,
  SupportedAppStatuses,
  AppDataType,
  App,
  Services,
  ManagedApp,
} from '@wix/app-market-services';
import { AppType } from '@wix/app-market-components';
import { getAppNotification } from './app-page-sections/notifications/api';
import type { AppNotification } from './app-page-sections/notifications/types';
import { getSubCategories } from './app-page-sections/quick-info/api';
import { getAppPricingPlans } from './app-page-sections/pricing-plans';
import type Experiments from '@wix/wix-experiments';
import type { Subcategory } from './app-page-sections/categories-tags';
import { isAppSupportedInResponsiveEditors } from './app-page-sections/notifications/editor-not-supported/api';

export interface getAppPageDataResponse {
  app: Omit<AppDataType, 'company'> & {
    type: AppType;
    isInstalled: boolean;
    isUpgradable: boolean;
    instanceId?: string;
    gTag?: string;
  };
  managedApp?: ManagedApp;
  overview: {
    benefits: Benefits[] | undefined;
    description: string;
    demoUrl: string | undefined;
  };
  companyInfo: {
    name: string;
    contactUs: string;
    image: string;
    websiteUrl: string;
    privacyPolicyUrl: string;
    slug: string;
  };
  reviews: getReviewsSectionResponse;
  notification: AppNotification | null;
  quickInfo: {
    subCategories: Subcategory[];
    media: Media[];
  };
  properties: {
    geoAvailability: MarketListing['geoAvailability'];
    supportedLanguages: string[];
    isAppContentTranslatable: boolean;
    appDependencies: {
      id: string;
      slug: string;
      name: string;
    }[];
    appCollections: {
      slug: string;
      name: string;
    }[];
  };
  pricingPlans:
    | {
        externalPricingPageUrl?: string;
        isExternalPricing: boolean;
        trialDays: number;
        tax: TaxDetails;
        currencySettings: CurrencySettings;
        companyName: string;
        appName: string;
        plans: Plan[];
        appId: string;
        isFreeApp: boolean;
      }
    | undefined;
}

export async function getAppPageDataById({
  id,
  languageCode,
  status,
  siteContext,
  services,
  experiments,
}: {
  id: string;
  languageCode: string;
  status: SupportedAppStatuses;
  siteContext: ISiteContext;
  services: Services;
  experiments: Experiments;
}): Promise<getAppPageDataResponse> {
  const apps = await services.apps.query({ appIds: [id] });
  const app = apps.findByAppId(id);
  if (app.id === '') {
    throw new Error(
      `App with id ${id} not found, languageCode: ${languageCode}, status: ${status}`,
    );
  }

  return getAppPageData({
    app,
    languageCode,
    status,
    siteContext,
    services,
    experiments,
  });
}

export async function getAppPageDataBySlug({
  slug,
  languageCode,
  status,
  siteContext,
  services,
  experiments,
}: {
  slug: string;
  languageCode: string;
  status: SupportedAppStatuses;
  siteContext: ISiteContext;
  services: Services;
  experiments: Experiments;
}): Promise<getAppPageDataResponse> {
  const apps = await services.apps.query({ slugs: [slug] });
  const app = apps.findBySlug(slug);
  if (app.id === '') {
    throw new Error(
      `App with slug ${slug} not found, languageCode: ${languageCode}, status: ${status}`,
    );
  }

  return getAppPageData({
    app,
    languageCode,
    status,
    siteContext,
    services,
    experiments,
  });
}

async function getAppPageData({
  app,
  languageCode,
  status,
  siteContext,
  services,
  experiments,
}: {
  app: App;
  languageCode: string;
  status: SupportedAppStatuses;
  siteContext: ISiteContext;
  services: Services;
  experiments: Experiments;
}): Promise<getAppPageDataResponse> {
  const usePlansV2 = experiments.enabled(
    'specs.app-market.PlansV2insteadPricingModel',
  );
  const shouldUseAnalyticsService = experiments.enabled(
    'specs.app-market.shouldUseAnalyticsService',
  );
  const shouldUseResearchGtag =
    app.id === 'cc720f6c-2e70-49fc-bad1-cb8fed2db1a3' &&
    !shouldUseAnalyticsService; // TODO: remove after research

  const shouldReportGA = experiments.enabled(
    'specs.app-market.enableGoogleAnalytics',
  );

  const [
    { supportedInEditorX, supportedInStudio },
    appsData,
    { marketListing, appDependencies },
    managedApps,
    appPricingPlans,
    reviews,
    { subCategories },
    appCollections,
    marketApp,
    googleAnalyticsConfigs,
  ] = await Promise.all([
    isAppSupportedInResponsiveEditors({
      services,
      appId: app.id,
      experiments,
    }),
    services.getAppsByAppIds({ appIds: [app.id], status }),
    getAppMarketListingAndDependencies({
      services,
      appId: app.id,
      languageCode,
      status,
    }),
    siteContext.metaSiteId ? services.getManagedApps({}) : undefined,
    status !== 'APPROVED'
      ? getAppPricingPlans({
          services,
          appId: app.id,
          languageCode,
          status,
          usePlansV2,
        })
      : undefined,
    getReviewsSectionData({
      services,
      appId: app.id,
    }),
    getSubCategories({
      appId: app.id,
      services,
      languageCode,
    }),
    getAppCollections({
      services,
      appId: app.id,
      languageCode,
    }),
    services.marketApp.get({ appId: app.id }),
    shouldReportGA && shouldUseAnalyticsService
      ? services.googleAnalytics.query({
          id: app.id,
        })
      : undefined,
  ]);

  if (status === 'PUBLISHED' && marketApp.status !== 'PUBLISHED') {
    throw new Error(
      `trying to view published app page, appId: ${app.id}, but the app is not published`,
    );
  }

  const managedApp = managedApps?.findByAppId(app.id);
  const appData = appsData.findByAppId(app.id);

  const notification = managedApps
    ? await getAppNotification({
        services,
        managedApps,
        app: {
          id: app.id,
          name: marketListing.appName,
          dependencies: appDependencies,
          isPremiumSiteRequired:
            !!appData.installation.requirements?.premiumSite,
          supportedInEditorX,
          supportedInStudio,
          badges: appData.appBadges,
        },
        siteContext,
        experiments,
      })
    : null;

  const contactUsLink = app.isWixApp
    ? 'https://www.wix.com/contact'
    : `mailto:${marketApp.supportEmails.join(',')}`;

  return {
    app: {
      ...appData,
      ...{
        id: app.id,
        name: marketListing.appName,
        icon: marketListing.appIcon,
        type: app.isWixApp ? AppType.WIX_APP : AppType.TPA,
        isInstalled: Boolean(managedApps?.isAppInstalled(app.id)),
        isUpgradable: !!managedApp?.isAppEligibleForUpgrade,
        instanceId: managedApp?.instanceId,
        gTag: shouldReportGA
          ? shouldUseAnalyticsService
            ? googleAnalyticsConfigs?.findByAppId(app.id).googleTagId
            : shouldUseResearchGtag
            ? 'G-K3BV3BGJQX'
            : undefined
          : undefined,
      },
    },
    managedApp,
    overview: {
      benefits: marketListing.benefits,
      demoUrl: marketListing.demoUrl,
      description: marketListing.description,
    },
    companyInfo: {
      name: appData.company.name,
      contactUs: contactUsLink,
      image: appData.company.icon,
      privacyPolicyUrl: appData.company.privacyPolicyUrl,
      websiteUrl: appData.company.website,
      slug: appData.company.slug,
    },
    reviews,
    notification,
    quickInfo: {
      subCategories,
      media: [...marketListing.videoMedia, ...marketListing.imagesMedia],
    },
    properties: {
      geoAvailability: marketListing.geoAvailability,
      supportedLanguages: marketListing.supportedLanguages,
      isAppContentTranslatable: marketListing.isAppContentTranslatable,
      appDependencies: appDependencies.filter(
        (appDependency) => appDependency.isPublished,
      ),
      appCollections,
    },
    pricingPlans: appPricingPlans && {
      externalPricingPageUrl: marketListing.externalPricingPageUrl,
      isExternalPricing: appPricingPlans.isExternalPricing,
      trialDays: appData.freeTrialDays,
      tax: appPricingPlans.taxSettings,
      companyName: appData.company.name,
      appName: appData.name,
      currencySettings: appPricingPlans.currency,
      plans: appPricingPlans.plans.slice(0, 4),
      appId: app.id,
      isFreeApp: appPricingPlans.isFreeApp,
    },
  };
}

async function getAppMarketListingAndDependencies({
  services,
  appId,
  languageCode,
  status,
}: {
  services: Services;
  appId: string;
  languageCode: string;
  status: SupportedAppStatuses;
}): Promise<{
  marketListing: MarketListing;
  appDependencies: {
    id: string;
    slug: string;
    name: string;
    isPublished: boolean;
  }[];
}> {
  const marketListings = await services.marketListing.query({
    appIds: [appId],
    languageCodes: [languageCode],
    status,
  });

  // always getting languageCode en app dependencies due to server corrupted data
  const appDependenciesIds = marketListings.findByAppId(
    appId,
    'en',
  ).appDependenciesIds;

  const enrichedAppDependencies =
    appDependenciesIds.length > 0
      ? await enrichAppIds({
          services,
          languageCode,
          appIds: appDependenciesIds,
        })
      : [];

  return {
    marketListing: marketListings.findByAppId(appId, languageCode),
    appDependencies: enrichedAppDependencies,
  };
}

async function enrichAppIds({
  services,
  languageCode,
  appIds,
}: {
  services: Services;
  languageCode: string;
  appIds: string[];
}): Promise<
  {
    id: string;
    slug: string;
    name: string;
    isPublished: boolean;
  }[]
> {
  const [marketApps, apps, marketListings] = await Promise.all([
    services.marketApp.query({
      appIds,
    }),
    services.apps.query({
      appIds,
    }),
    services.marketListing.query({
      appIds,
      languageCodes: [languageCode],
      status: 'PUBLISHED',
    }),
  ]);

  return appIds.map((appId) => {
    const marketApp = marketApps.findByAppId(appId);
    const app = apps.findByAppId(appId);
    const marketListing = marketListings.findByAppId(appId, languageCode);

    return {
      id: appId,
      name: marketListing.appName,
      slug: app.slug,
      isPublished: marketApp.status === 'PUBLISHED',
    };
  });
}

async function getAppCollections({
  services,
  appId,
  languageCode,
}: {
  services: Services;
  appId: string;
  languageCode: string;
}): Promise<
  {
    slug: string;
    name: string;
  }[]
> {
  const tagApps = await services.queryTagApp({
    appIds: [appId],
  });
  const tags = await services.queryTag({
    tagIds: tagApps.tagIds,
    tagType: 'COLLECTION',
    isHidden: false,
    languageCode,
  });

  return tags.map((tag) => {
    return {
      slug: tag.slug,
      name: tag.name,
    };
  });
}
