import type { Section } from '@wix/ambassador-appmarket-v1-section/types';
import { MarketSection } from './market-section';
import type {
  AppBenefitSection,
  HomepageSection,
  SidebarSection,
} from './types';

export class MarketSections {
  private readonly marketSections: MarketSection[];

  constructor(sections: Section[] = []) {
    this.marketSections = sections.map((section) => new MarketSection(section));
  }

  toSidebarSections(): SidebarSection[] {
    return this.marketSections.map((marketSection) =>
      marketSection.toSidebarSection(),
    );
  }

  toAppBenefitSections(): AppBenefitSection[] {
    return this.marketSections.map((marketSection) =>
      marketSection.toAppBenefitSection(),
    );
  }

  toHomePageSections(): HomepageSection[] {
    return this.marketSections.map((marketSection) =>
      marketSection.toHomePageSection(),
    );
  }
}
