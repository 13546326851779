import type {
  PanoramaClient,
  panoramaClientFactory,
} from '@wix/panorama-client-web';
import { Transaction } from './transaction';
import type { AnyObject, IComponentMonitor } from './types';

export class ComponentMonitor implements IComponentMonitor {
  private panoramaClient: PanoramaClient;
  private hasStarted: boolean;
  private hasFinished: boolean;
  private globalParams: AnyObject;
  private transactions: Map<string, Transaction>;

  constructor(
    componentId: string,
    private panoramaFactory: ReturnType<typeof panoramaClientFactory>,
    globalParams: AnyObject,
  ) {
    this.panoramaClient = this.panoramaFactory.client({
      baseParams: {
        componentId,
      },
    });
    this.hasStarted = false;
    this.hasFinished = false;
    this.globalParams = globalParams;
    this.transactions = new Map();
  }

  reportError(error: Error, params?: AnyObject): void {
    this.panoramaClient.errorMonitor().reportError(error, {
      ...this.globalParams,
      ...params,
    });
  }

  reportLoadStart(params?: AnyObject): void {
    if (!this.hasStarted) {
      this.panoramaClient.reportLoadStart({ ...this.globalParams, ...params });
      this.hasStarted = true;
    }
  }

  reportLoadFinish(params?: AnyObject): void {
    if (!this.hasStarted) {
      throw new Error(
        'Finish transaction should not be initiated before start transaction',
      );
    }
    if (!this.hasFinished) {
      this.panoramaClient.reportLoadFinish({ ...this.globalParams, ...params });
      this.hasFinished = true;
    }
  }

  transaction(transactionId: string, uuid?: string): Transaction {
    const monitorId = `${transactionId}-${uuid}`;
    const transaction = this.transactions.get(monitorId);
    if (transaction) {
      return transaction;
    }
    const newTransaction = new Transaction(
      this.panoramaClient.transaction(transactionId),
      this.globalParams,
    );
    this.transactions.set(monitorId, newTransaction);
    return newTransaction;
  }
}
