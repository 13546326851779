import React from 'react';
import { Badge } from '../badge';
import { getBundleBadgeTooltipContent } from './bundle-badge.utils';
import { useEssentials } from '../../../contexts/essentials-context';
import { Box } from '@wix/design-system';

export interface AppPromoBoxProps {
  isInstalled: boolean;
  isCouponTypeBundle: boolean;
  appName: string;
  showBorder?: boolean;
  size?: 'tiny' | 'small' | 'medium';
}

export function BundleBadge({
  isInstalled,
  isCouponTypeBundle,
  appName,
  showBorder,
  size = 'small',
}: AppPromoBoxProps) {
  const { t } = useEssentials();

  return (
    <Badge
      skin="neutralSuccess"
      dataHook="bundle-badge"
      uppercase={false}
      showBorder={showBorder}
      size={size}
      tooltipContent={getBundleBadgeTooltipContent({
        t,
        isInstalled: isInstalled ?? false,
        isCouponTypeBundle,
        appName: appName ?? '',
      })}
    >
      <Box>{t('solutionBox.badge.bundle')}</Box>
    </Badge>
  );
}
