import React from 'react';
import {
  useEssentials,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import {
  getSubCategoryComponentData,
  queryApps as queryAppsData,
} from './sub-category-page-data';
import { AppMarketPage } from '../../enums';
import type { QueryApps } from '../../components/apps-section';
import { AppsSection } from '../../components/apps-section';
import type { QueryAppsFilter } from '@wix/app-market-services';
import { QueryDecorator } from '../../decorators';
import { queryKeyBuilder } from '../../components/apps-section/app-section-helper';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { AppsSectionSkeleton } from '../../components/apps-section/apps-section-skeletons';
import { useQueryClient } from '@tanstack/react-query';

export function SubCategoryAppsSection({ slug }: { slug: string }) {
  const { experiments } = useEssentials();
  const { languageCode } = useSiteContext();
  const services = useServices();
  const queryClient = useQueryClient();
  const { route } = useRouterContext();
  const pageName = AppMarketPage.SUB_CATEGORY;
  const isSale = Boolean(
    (route.payload as RoutePayloadMap[RoutePath.SUB_CATEGORY]).isSale,
  );

  const queryApps: QueryApps = async ({
    offset,
    filter,
  }: {
    offset?: number;
    filter?: QueryAppsFilter;
  }) => {
    const { appGroup, paging, collectionName } = await queryAppsData({
      services,
      experiments,
      slug,
      languageCode,
      offset,
      filter,
    });

    return {
      appGroup,
      paging,
      collectionName,
    };
  };

  return (
    <QueryDecorator
      queryFn={() =>
        getSubCategoryComponentData({
          queryClient,
          experiments,
          slug,
          services,
          languageCode,
          isSale,
        })
      }
      queryKey={queryKeyBuilder({
        queryName: 'initial-apps-fetch',
        language: languageCode,
        isSale,
        appsLength: 0,
        queryId: slug,
      })}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <AppsSectionSkeleton />
        ) : (
          <AppsSection
            origin={pageName}
            queryApps={queryApps}
            queryId={slug}
            initData={data}
          />
        )
      }
    </QueryDecorator>
  );
}
