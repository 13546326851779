import React from 'react';
import type { ButtonSize } from '@wix/design-system';
import { Box, Button, Loader } from '@wix/design-system';
import type { AppBadge } from '@wix/app-market-services';
import { useTranslation } from '../../../../contexts/translations-context';
import {
  useEssentials,
  useServices,
  useSiteContext,
} from '../../../../contexts';
import { useUpgradeButtonBiEvents } from './use-upgrade-bi-event';
import { QueryDecorator } from '../../../../decorators';
import { hasBenefitOrBundleBadge } from '../../has-benefit-or-bundle-badge';

export const UPGRADE_APP_BUTTON_DATA_HOOK = 'upgrade-app-button';

export interface UpgradeAppButtonProps {
  app: { id: string; instanceId?: string; appBadges: AppBadge[] };
  size: ButtonSize;
  origin: string;
}

export function UpgradeAppButton({ app, size, origin }: UpgradeAppButtonProps) {
  const services = useServices();
  const { experiments } = useEssentials();
  const { t } = useTranslation();
  const { metaSiteId } = useSiteContext();
  const showRedeemOfferButton = hasBenefitOrBundleBadge({
    experiments,
    appBadges: app.appBadges,
  });
  const { reportUpgradeClick, reportFreeTrialClick } = useUpgradeButtonBiEvents(
    {
      app,
      origin,
      showRedeemOfferButton,
    },
  );

  return (
    <QueryDecorator
      queryFn={async () => {
        const freeTrialAppIds = await (metaSiteId
          ? services.getEligibleAppsForFreeTrial()
          : Promise.resolve([]));

        return freeTrialAppIds.includes(app.id);
      }}
      queryKey={`upgrade-app-button-${app.id}`}
      csrOnly
    >
      {({ data: isAppEligibleForFreeTrial, isLoading }) => {
        return (
          <Box direction="vertical" flexGrow={1}>
            <Button
              dataHook={UPGRADE_APP_BUTTON_DATA_HOOK}
              disabled={isLoading}
              skin={isAppEligibleForFreeTrial ? 'standard' : 'premium'}
              size={size}
              onClick={() => {
                if (isAppEligibleForFreeTrial) {
                  reportFreeTrialClick();
                } else {
                  reportUpgradeClick();
                }

                const appPackagePickerUrl = `https://www.wix.com/apps/upgrade/${app.id}?metaSiteId=${metaSiteId}&pp_origin=app_market_app_page`;
                window.open(appPackagePickerUrl);
              }}
            >
              {isLoading ? (
                <Loader size="tiny" />
              ) : isAppEligibleForFreeTrial ? (
                t('app.page.upgrade.cta.label.free.trial')
              ) : showRedeemOfferButton ? (
                t('app.page.upgrade.cta.label.benefit')
              ) : (
                t('app.page.upgrade.cta.label')
              )}
            </Button>
          </Box>
        );
      }}
    </QueryDecorator>
  );
}
