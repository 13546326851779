import React from 'react';
import type { ErrorInfo } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorPageContainer } from '../error-page/error-page-container';
import { ErrorPageType } from '../..';
import {
  Route,
  useEssentials,
  useRouterContext,
  useSiteContext,
} from '@wix/app-market-core';

export interface IErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary: React.FC<IErrorBoundaryProps> = ({ children }) => {
  const { marketWatch } = useEssentials();
  const { baseURL } = useSiteContext();
  const { route } = useRouterContext();
  const routeClass = new Route({
    path: route.path,
    payload: route.payload,
    baseURL,
  });

  const handleError = (error: Error, info: ErrorInfo) => {
    marketWatch
      .monitor(routeClass.toComponentId())
      .reportError(error, { info });
  };

  return (
    <ReactErrorBoundary
      key={JSON.stringify(routeClass.toJSON())}
      FallbackComponent={() => (
        <ErrorPageContainer errorPageType={ErrorPageType.INTERNAL_SERVER} />
      )}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
};
