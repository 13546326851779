import { RequestService } from '../request-service';
import type {
  QueryMyAppsRequest,
  QueryMyAppsResponse,
} from '@wix/ambassador-devcenter-myapps-v1-my-app/types';
import type RpcModule from '@wix/ambassador-devcenter-myapps-v1-my-app/rpc';
import type HttpModule from '@wix/ambassador-devcenter-myapps-v1-my-app/http';

export type QueryMyApps = {
  languageCodes: string[];
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class MyAppsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-myapps-v1-my-app/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-myapps-v1-my-app/http');
  }

  public async query({}: QueryMyApps) {
    const response = await this.request<
      QueryMyAppsRequest,
      QueryMyAppsResponse
    >({
      methodName: 'queryMyApps',
      payload: {
        query: {
          cursorPaging: { limit: 200 },
          filter: {
            myApps: {
              appData: { appType: 'SITE_APP', archived: false },
              marketData: { versionStatus: 'PUBLISHED' },
            },
          },
        },
      },
    });
    return response;
  }
}
