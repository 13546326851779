import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider } from '@wix/design-system';
import { CenteredLoader } from '../../components/centered-loader';
import { QueryDecorator } from '../../decorators';
import {
  appMarketDemoLinkClicked,
  appMarketDeveloperContactBoxActionClick,
  appMarketPageView,
  appMarketAppPageScrollPage,
  appMarketPageStartLoadedSrc24Evid163,
} from '@wix/bi-logger-app-market-data/v2';
import { getAppPageDataById, getAppPageDataBySlug } from './app-page-api';
import {
  isAppWithBenefits,
  type SupportedAppStatuses,
} from '@wix/app-market-services';
import {
  useEssentials,
  useJunkYard,
  useSiteContext,
  useServices,
  useRouterContext,
} from '../../contexts';
import { AppNotification } from './app-page-sections/notifications/app-notification';
import { ReviewsSection } from './app-page-sections/reviews/reviews-section';
import {
  AppPageSticky,
  QuickInfo,
  CompanyInfo,
  AppOverview,
  VisibilityDetector,
  AppType,
} from '@wix/app-market-components';
import { AppProperties } from './app-page-sections/app-properties';
import { AppPageCTAs } from './app-page-ctas';
import { useQuickInfoActions } from './app-page-sections/quick-info/quick-info-actions';
import type { getAppPageDataResponse } from './app-page-api';
import { AppPricingPlansSection } from './app-page-sections/pricing-plans';
import { getAppPageViewBiData } from '../../bi-services/events-data-parser';
import { AppMarketPage } from '../../enums';
import type { getReviewsSectionResponse } from './app-page-sections/reviews/reviews-api';
import { RecommendedForYouSection } from './app-page-sections/recommended-for-you';
import s from './app-page.scss';
import { BackButton } from '../../components/back-button';
import type { AppPageOptions } from './types';
import { appPageDataHooks } from './data-hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AppPageSEO } from './app-page-seo';
import { withSaleBanner } from '../../components/sale-banner';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';
import { appPageId } from './app-page-id';
import { EventName } from '@wix/app-market-analytics';
import { useCreateGoogleAnalytics } from '../../hooks/use-create-google-analytics';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { getPageViewAnalyticsParams } from '../../bi-services/utils';

export interface AppPageProps {
  id?: string;
  slug?: string;
  status?: SupportedAppStatuses;
  options?: AppPageOptions;
}

export interface AppPageComponentProps extends getAppPageDataResponse {
  onChange: (reviews: getAppPageDataResponse['reviews']) => void;
  onLoad: () => void;
  status?: SupportedAppStatuses;
  options?: AppPageOptions;
}

export function AppPageComponent({
  app,
  managedApp,
  notification,
  reviews,
  overview,
  quickInfo,
  properties,
  companyInfo,
  onChange,
  onLoad,
  pricingPlans,
  status = 'PUBLISHED',
  options = {},
}: AppPageComponentProps) {
  useMemo(() => onLoad(), [app.id]);
  const {
    onCompanyClick,
    onBack: onBackAction,
    onTagClick,
    onReviewsClick,
    onGalleryChange,
    onExpandDescriptionButtonClick,
  } = useQuickInfoActions();
  const { i18n, biLogger, experiments } = useEssentials();
  const { metaSiteId, isPremium } = useSiteContext();
  const { router, route } = useRouterContext();
  const gaInstance = useCreateGoogleAnalytics(app.gTag);
  const { routerData, goBackToReferrer } = useJunkYard();
  const [shouldShowHeader, setShouldShowHeader] = useState<boolean>(false);
  const onBack = router.hasHistory() ? () => onBackAction(app.id) : undefined;
  const {
    showRecommendedForYou = true,
    showHeaderLinks = true,
    stickyHeaderOptions,
    pricingOptions,
    showReviews = true,
    showRating,
  } = options;

  useEffect(() => {
    const handlePageLoadAnalytics = async () => {
      gaInstance?.reportAutomaticEvent({
        name: EventName.PAGE_VIEW,
        params: getPageViewAnalyticsParams(
          route.payload as RoutePayloadMap[RoutePath.APP_PAGE],
        ),
      });
    };
    onAppPageLoad({ app, reviews, properties });
    handlePageLoadAnalytics();
  }, [app]);

  const onSectionFirstVisible = ({
    sectionName,
    sectionIndex,
  }: {
    sectionName: string;
    sectionIndex: number;
  }) => {
    biLogger.report(
      appMarketAppPageScrollPage({
        app_id: app.id,
        page_part: sectionName,
        page_part_index: sectionIndex,
        tag_name: 'app_page',
      }),
    );
  };

  const onAppPageLoad = (props: {
    app: getAppPageDataResponse['app'];
    reviews: getAppPageDataResponse['reviews'];
    properties: getAppPageDataResponse['properties'];
  }) => {
    const searchTerm = location.search.split('referralTag=')?.[1];
    biLogger.report(
      appMarketPageView(
        getAppPageViewBiData({
          routerData,
          pageType: AppMarketPage.APP_PAGE,
          appId: props.app.id,
          appSlug: props.app.slug,
          appReviewCount: props.reviews.reviewsSummary.totalReviews,
          appReviewScore: props.reviews.reviewsSummary.averageRating,
          numberOfDependedApps: props.properties.appDependencies.length,
          searchTerm,
          appBadges: props.app.appBadges,
          referrerNameForBI: goBackToReferrer?.referrerNameForBI,
        }),
      ),
    );
  };

  return (
    <div data-hook={appPageDataHooks.APP_PAGE_COMPONENT}>
      <AppPageSticky
        isVisible={shouldShowHeader}
        icon={app.icon}
        company={companyInfo}
        installation={app.installation}
        pricing={app.pricing}
        appBadges={app.appBadges}
        onBack={onBack}
        mainActionButton={
          <AppPageCTAs
            managedApp={managedApp}
            status={status}
            app={{
              id: app.id,
              instanceId: app.instanceId,
              name: app.name,
              isUpgradable: app.isUpgradable,
              isAppWithBenefits: isAppWithBenefits(app.appBadges),
              isInstalled: app.isInstalled,
              isBuiltIn: Boolean(app.installation.isBuiltIn),
              appBadges: app.appBadges,
              notification,
              gTag: app.gTag,
            }}
            size="small"
            dataHook={appPageDataHooks.APP_STICKY_HEADER_CTA}
            reverseOrder
          />
        }
        onCompanyClick={(companyName: string, companySlug: string) =>
          onCompanyClick(companyName, companySlug, app.id)
        }
        name={app.name}
        isAppUpgradable={app.isUpgradable}
        i18n={i18n}
        experiments={experiments}
        isPremiumSite={isPremium}
        options={stickyHeaderOptions}
      />
      <div className={s.appPage}>
        {showHeaderLinks && (
          <Box marginTop="18px">
            {onBack && (
              <Box dataHook={appPageDataHooks.BACK_BUTTON}>
                <BackButton
                  size="tiny"
                  weight="thin"
                  skin="dark"
                  onBack={onBack}
                />
              </Box>
            )}
          </Box>
        )}

        {notification ? (
          <Box marginTop="12px" marginBottom="12px">
            <AppNotification notification={notification} />
          </Box>
        ) : null}

        <QuickInfo
          media={quickInfo.media}
          app={app}
          onCompanyClick={(companyName: string, companySlug: string) =>
            onCompanyClick(companyName, companySlug, app.id)
          }
          onReviewsClick={(reviewsAmount: number) =>
            onReviewsClick(app.id, reviewsAmount)
          }
          mainActionButton={
            <VisibilityDetector
              onVisible={() => setShouldShowHeader(false)}
              onHidden={() => setShouldShowHeader(true)}
            >
              <Box align="center" marginTop={app.isUpgradable ? '6px' : 0}>
                <AppPageCTAs
                  managedApp={managedApp}
                  status={status}
                  app={{
                    id: app.id,
                    instanceId: app.instanceId,
                    name: app.name,
                    isUpgradable: app.isUpgradable,
                    isAppWithBenefits: isAppWithBenefits(app.appBadges),
                    isInstalled: app.isInstalled,
                    isBuiltIn: Boolean(app.installation.isBuiltIn),
                    appBadges: app.appBadges,
                    notification,
                    gTag: app.gTag,
                  }}
                  dataHook={appPageDataHooks.APP_PAGE_CTA}
                  size={app.isUpgradable ? 'small' : 'large'}
                />
              </Box>
            </VisibilityDetector>
          }
          onGalleryChange={(oldIndex: number, newIndex: number) =>
            onGalleryChange(oldIndex, newIndex, app.id)
          }
          i18n={i18n}
          experiments={experiments}
          isPremiumSite={isPremium}
          options={{ showRating }}
        />

        <Box margin="36px 0px">
          <Divider />
        </Box>

        <VisibilityDetector
          onFirstVisible={() =>
            onSectionFirstVisible({
              sectionName: 'SolutionOverview',
              sectionIndex: 1,
            })
          }
        >
          <Box className={s.mainContent} gap="24px">
            <Box flex={1}>
              <AppOverview
                appName={app.name}
                description={overview.description}
                benefits={overview.benefits}
                demoUrl={overview.demoUrl}
                onClickDemoUrl={() => {
                  biLogger.report(
                    appMarketDemoLinkClicked({
                      app_id: app.id,
                      demo_link: overview.demoUrl,
                    }),
                  );
                }}
                onExpandToggleClick={() =>
                  onExpandDescriptionButtonClick?.(app.id)
                }
                i18n={i18n}
              />
            </Box>
            <Box
              className={s.propertiesCompanyContainer}
              direction="vertical"
              gap="24px"
            >
              <AppProperties
                appId={app.id}
                appName={app.name}
                isWixApp={app.type === AppType.WIX_APP}
                geoAvailability={properties.geoAvailability}
                appCollections={properties.appCollections}
                supportedLanguages={properties.supportedLanguages}
                isAppContentTranslatable={properties.isAppContentTranslatable}
                appDependencies={properties.appDependencies}
                subCategories={showHeaderLinks ? quickInfo.subCategories : []}
                onTagClick={(
                  categorySlug: string,
                  subCategory: string,
                  label: 'category' | 'sub_category',
                  location: 'side_category_page',
                ) =>
                  onTagClick(categorySlug, subCategory, app.id, label, location)
                }
              />
              <CompanyInfo
                appInfo={{
                  name: companyInfo.name,
                  imageURL: companyInfo.image,
                }}
                website={{
                  href: companyInfo.websiteUrl,
                  onClick: () => {
                    biLogger.report(
                      appMarketDeveloperContactBoxActionClick({
                        app_id: app.id,
                        actionName: 'visit_website',
                      }),
                    );
                  },
                }}
                contactSupport={{
                  href: companyInfo.contactUs,
                  onClick: () => {
                    biLogger.report(
                      appMarketDeveloperContactBoxActionClick({
                        app_id: app.id,
                        actionName: 'contact_support',
                      }),
                    );
                  },
                }}
                privacyPolicy={{
                  href: companyInfo.privacyPolicyUrl,
                  onClick: () => {
                    biLogger.report(
                      appMarketDeveloperContactBoxActionClick({
                        app_id: app.id,
                        actionName: 'privacy',
                      }),
                    );
                  },
                }}
                i18n={i18n}
              />
            </Box>
          </Box>
        </VisibilityDetector>

        {showReviews && (
          <>
            <Box margin="36px 0px">
              <Divider dataHook="reviews-top-divider" />
            </Box>
            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionReviews',
                  sectionIndex: 2,
                })
              }
            >
              <ReviewsSection
                app={app}
                company={companyInfo}
                reviews={reviews}
                onChange={onChange}
              />
            </VisibilityDetector>
          </>
        )}

        {pricingPlans && !pricingPlans.isFreeApp && (
          <>
            <Box margin="36px 0px">
              <Divider />
            </Box>

            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionPricingPlans',
                  sectionIndex: 3,
                })
              }
            >
              <AppPricingPlansSection
                {...{ ...pricingPlans, options: pricingOptions }}
              />
            </VisibilityDetector>
          </>
        )}

        {showRecommendedForYou && metaSiteId && (
          <>
            <Box margin="36px 0px">
              <Divider />
            </Box>
            <VisibilityDetector
              onFirstVisible={() =>
                onSectionFirstVisible({
                  sectionName: 'SolutionRelatedApps',
                  sectionIndex: 4,
                })
              }
            >
              <RecommendedForYouSection appId={app.id} />
            </VisibilityDetector>
          </>
        )}
      </div>
    </div>
  );
}

export const AppPage = withSaleBanner(function ({
  id,
  slug,
  status = 'PUBLISHED',
  options,
}: AppPageProps) {
  const siteContext = useSiteContext();
  const languageCode = siteContext.languageCode;
  const queryKey = `${appPageId}-${id || slug}-${languageCode}-${status}`;

  const reportLoadFinish = useComponentLoadReporter({
    componentId: appPageId,
    params: { appId: id, slug },
    deps: [queryKey],
  });
  const { biLogger, experiments } = useEssentials();
  const queryClient = useQueryClient();
  const services = useServices();
  const [reviews, setReviews] = useState<
    getReviewsSectionResponse | undefined
  >();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.APP_PAGE,
      }),
    );
  }, [slug]);

  return (
    <QueryDecorator
      queryFn={() =>
        id
          ? getAppPageDataById({
              services,
              id,
              languageCode,
              siteContext,
              status,
              experiments,
            })
          : slug
          ? getAppPageDataBySlug({
              services,
              slug,
              languageCode,
              status,
              siteContext,
              experiments,
            })
          : Promise.reject(new Error('No id or slug provided'))
      }
      queryKey={queryKey}
    >
      {({ data, isLoading }) => {
        if (isLoading) {
          return <CenteredLoader />;
        }

        return (
          <>
            {!siteContext.metaSiteId && (
              <AppPageSEO
                description={data.app.shortDescription}
                metaTagsAggregator={siteContext.metaTagsAggregator ?? []}
                appName={data.app.name}
                reviewsSummary={data.reviews.reviewsSummary}
                icon={data.app.icon}
              />
            )}
            <AppPageComponent
              {...data}
              options={options}
              reviews={reviews ?? data.reviews}
              onChange={(newReviews: getReviewsSectionResponse) => {
                setReviews(newReviews);
                queryClient.invalidateQueries([queryKey]);
              }}
              onLoad={reportLoadFinish}
              status={status}
            />
          </>
        );
      }}
    </QueryDecorator>
  );
});
