import React from 'react';
import {
  Box,
  Cell,
  Divider,
  Layout,
  Text,
  TextButton,
  Popover,
} from '@wix/design-system';
import { appMarketAppPageHover } from '@wix/bi-logger-app-market-data/v2';

import { useTranslation } from '../../../../contexts/translations-context';
import {
  useServices,
  useEssentials,
  useUserContext,
} from '../../../../contexts';
import { AvailableCountriesMobileModal } from './available-countries-mobile-modal';

export interface AppAvailableCountriesProps {
  label: string;
  appId: string;
  appName: string;
  isWixApp: boolean;
  availableCountries: string[];
}

export const AppAvailableCountries = ({
  label,
  appId,
  isWixApp,
  appName,
  availableCountries,
}: AppAvailableCountriesProps) => {
  const { t } = useTranslation();
  const services = useServices();
  const { biLogger } = useEssentials();
  const { isMobile } = useUserContext();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);

  const [appSupportEmails, setAppSupportEmails] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!isWixApp) {
      services.marketApp
        .get({ appId })
        .then((app) => setAppSupportEmails(app.supportEmails));
    }
  }, []);

  if (isMobile) {
    return (
      <>
        <Box inline>
          <TextButton
            onClick={() => setIsMobileModalOpen(true)}
            size="small"
            weight="thin"
          >
            {label}
          </TextButton>
        </Box>
        <AvailableCountriesMobileModal
          isOpen={isMobileModalOpen}
          onClose={() => setIsMobileModalOpen(false)}
          appName={appName}
          availableCountries={availableCountries}
          contactSupportLink={
            isWixApp
              ? 'https://www.wix.com/contact'
              : `mailto:${appSupportEmails.join(';')}`
          }
        />
      </>
    );
  }

  return (
    <Popover
      showArrow
      animate
      placement="left"
      shown={isPopoverOpen}
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
      appendTo="scrollParent"
      moveBy={{ x: 4 }}
      onShow={() => {
        biLogger.report(
          appMarketAppPageHover({
            app_id: appId,
            location: 'properties',
            hover_type: 'other_countries',
          }),
        );
      }}
    >
      <Popover.Element>
        <Box inline>
          <TextButton size="small" weight="thin">
            {label}
          </TextButton>
        </Box>
      </Popover.Element>
      <Popover.Content>
        <Box
          width="402px"
          paddingTop="24px"
          paddingRight="18px"
          paddingBottom="18px"
          paddingLeft="24px"
          direction="vertical"
        >
          <Box
            maxHeight="276px"
            overflow="scroll"
            direction="vertical"
            gap="18px"
          >
            <Text size="small" weight="bold">
              {t('solution.page.tagsBoxAvailabilityPopoverTitle', {
                appName,
                interpolation: { escapeValue: false },
              })}
            </Text>
            <Box
              direction="vertical"
              marginRight="12px"
              marginLeft="12px"
              marginBottom="12px"
            >
              <Layout>
                {availableCountries.map((country) => (
                  <Cell key={country} span={6}>
                    -{' '}
                    <Text size="small" weight="thin">
                      {t(`marketplace.country.${country}`)}
                    </Text>
                  </Cell>
                ))}
              </Layout>
            </Box>
          </Box>
          <Box gap="18px" direction="vertical">
            <Divider />
            <Text size="small" weight="thin">
              {t('solution.page.tagsBoxAvailabilityPopoverFooter1')}
              <Box inline>
                <TextButton
                  as="a"
                  target="_blank"
                  href={
                    isWixApp
                      ? 'https://www.wix.com/contact'
                      : `mailto:${appSupportEmails.join(';')}`
                  }
                  size="small"
                  weight="thin"
                >
                  {t('solution.page.tagsBoxAvailabilityPopoverFooter2')}
                </TextButton>
              </Box>
            </Text>
          </Box>
        </Box>
      </Popover.Content>
    </Popover>
  );
};
