import { RequestService } from '../request-service';
import type {
  QueryAppReviewsSummaryRequest,
  QueryAppReviewsSummaryResponse,
} from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/types';
import type RpcModule from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/rpc';
import type HttpModule from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/http';
import { AppReviewsSummaries } from './appReviewSummaries';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

type QueryAppReviewsSummary = {
  appIds: string[];
};

export class AppReviewsSummaryService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import(
      '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/rpc'
    );
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import(
      '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/http'
    );
  }

  public async query(payload: QueryAppReviewsSummary) {
    const { appIds } = payload;
    if (!appIds.length) {
      throw new Error('appIds empty in queryAppReviewsSummary');
    }

    const response = await this.request<
      QueryAppReviewsSummaryRequest,
      QueryAppReviewsSummaryResponse
    >({
      methodName: 'queryAppReviewsSummary',
      payload: {
        query: {
          filter: {
            appId: { $in: appIds },
          },
        },
      },
    });

    return new AppReviewsSummaries(response.appReviewsSummaries || []);
  }
}
