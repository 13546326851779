import React, { useMemo } from 'react';
import { Box } from '@wix/design-system';
import type { Section } from '@wix/app-market-services';
import { Placement } from '@wix/app-market-services';
import { useServices, useSiteContext } from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { AppsSectionsSkeleton } from '../../components/apps-sections-skeleton';
import { HomePageSection } from './home-page-section';
import { homePageId } from './home-page-id';

export function HomePageSections({ onLoad }: { onLoad: () => void }) {
  const { metaSiteId } = useSiteContext();
  const services = useServices();

  return (
    <QueryDecorator
      queryFn={async () => {
        const { sections } = await services.getDynamicSections({
          placement: Placement.HOME_PAGE,
          isMetaSiteId: !!metaSiteId,
        });

        return sections;
      }}
      queryKey={homePageId}
    >
      {({ data: sections, isLoading }) =>
        isLoading ? (
          <AppsSectionsSkeleton />
        ) : (
          <Sections onLoad={onLoad} sections={sections} />
        )
      }
    </QueryDecorator>
  );
}

function Sections({
  sections,
  onLoad,
}: {
  sections: Section[];
  onLoad: () => void;
}) {
  useMemo(() => onLoad(), []);

  return sections.map((section, index) => (
    <Box key={index} direction="vertical" marginBottom="30px">
      <HomePageSection section={section} index={index} />
    </Box>
  ));
}
