import type { Plan as PlanType } from '@wix/ambassador-appmarket-v1-app-plans/types';
import type { IAppPlan, MeterdBilling, Discount, PlanPrice } from './types';
import { CycleType, DurationUnit } from './types';

/**
 *
 * Represents a facade for Plan type on the app-plans service.
 *
 * @class AppPlan
 */

export class AppPlan {
  constructor(
    private readonly plan: PlanType,
    private readonly showPriceWithTax: boolean,
  ) {}

  get vendorId(): string {
    return this.plan.vendorId ?? '';
  }

  get meterdBilling(): MeterdBilling | undefined {
    let meterdBilling;
    const meterdPrice = this.plan?.prices?.find(
      (price) => !!price.usageBaseOptions,
    );

    if (meterdPrice?.usageBaseOptions) {
      meterdBilling = {
        outline: meterdPrice.usageBaseOptions.customChargeDescription ?? '',
        baseFee: meterdPrice.usageBaseOptions.monthlyBaseFee ?? '',
        discountBaseFee: this.showPriceWithTax
          ? meterdPrice.discount?.totalPrice
          : meterdPrice.discount?.priceBeforeTax,
      };
    }
    return meterdBilling;
  }

  get yearlyPrice(): PlanPrice | undefined {
    const price = this.plan?.prices?.find(
      (_price) =>
        _price.billingCycle?.cycleDuration?.unit === DurationUnit.YEAR &&
        _price.billingCycle?.cycleType === CycleType.RECURRING,
    );
    return {
      price: this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax,
      discountPrice: this.showPriceWithTax
        ? price?.discount?.totalPrice
        : price?.discount?.priceBeforeTax,
    };
  }

  get monthlyPrice(): PlanPrice | undefined {
    const price = this.plan?.prices?.find(
      (_price) =>
        _price.billingCycle?.cycleDuration?.unit === DurationUnit.MONTH &&
        _price.billingCycle?.cycleType === CycleType.RECURRING,
    );
    return {
      price: this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax,
      discountPrice: this.showPriceWithTax
        ? price?.discount?.totalPrice
        : price?.discount?.priceBeforeTax,
    };
  }

  get oneTimePrice(): PlanPrice | undefined {
    const price = this.plan?.prices?.find(
      (_price) => _price.billingCycle?.cycleType === CycleType.ONE_TIME,
    );
    return {
      price: this.showPriceWithTax ? price?.totalPrice : price?.priceBeforeTax,
      discountPrice: this.showPriceWithTax
        ? price?.discount?.totalPrice
        : price?.discount?.priceBeforeTax,
    };
  }

  /**
   *  There are 2 types of discounts: Sale and App Benefit.
   *  An app can have both active discounts, only one per plan.
   */

  get discount(): Discount | undefined {
    const discount = this.plan?.prices?.find((price) =>
      Boolean(price.discount?.sourceType),
    )?.discount;
    if (!discount) {
      return undefined;
    }
    const { amount, type, sourceType, saleOptions } = discount;

    if (amount && type && sourceType) {
      const planDiscount: Discount = {
        sourceType,
        saleType: saleOptions?.saleType,
        type,
        amount,
      };
      return planDiscount;
    }
    return undefined;
  }

  get billingSource() {
    return this.plan?.prices?.find((price) => price.billingSource)
      ?.billingSource;
  }

  toJSON(): IAppPlan {
    return {
      meterdBilling: this.meterdBilling,
      yearlyPrice: this.yearlyPrice,
      monthlyPrice: this.monthlyPrice,
      oneTimePrice: this.oneTimePrice,
      vendorId: this.vendorId,
      discount: this.discount,
      billingSource: this.billingSource,
    };
  }
}
