import React from 'react';
import {
  useEssentials,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { queryApps as queryAppsData } from './collection-page-data';
import { AppMarketPage } from '../../enums';
import type { QueryApps } from '../../components/apps-section';
import { AppsSection } from '../../components/apps-section';
import type { QueryAppsFilter } from '@wix/app-market-services';
import type { RoutePath, RoutePayloadMap } from '../../models';
import { QueryDecorator } from '../../decorators';
import { queryKeyBuilder } from '../../components/apps-section/app-section-helper';
import { AppsSectionSkeleton } from '../../components/apps-section/apps-section-skeletons';
import { useTranslation } from '../../contexts/translations-context';
import { Box, EmptyState } from '@wix/design-system';

export function CollectionAppsSection({ slug }: { slug: string }) {
  const { experiments } = useEssentials();
  const { languageCode } = useSiteContext();
  const { t } = useTranslation();
  const services = useServices();
  const { route } = useRouterContext();

  const isSale = Boolean(
    (route.payload as RoutePayloadMap[RoutePath.COLLECTION]).isSale,
  );
  const pageName = AppMarketPage.COLLECTION;
  const isSaleCollection =
    (route.payload as RoutePayloadMap[RoutePath.COLLECTION]).slug ===
    'holiday-sale';

  const queryApps: QueryApps = async ({
    offset,
    filter,
  }: {
    offset?: number;
    filter?: QueryAppsFilter;
  }) => {
    const { appGroup, paging, collectionName } = await queryAppsData({
      services,
      experiments,
      slug,
      languageCode,
      offset,
      filter,
    });

    return {
      appGroup,
      paging,
      collectionName,
    };
  };

  const EmptyStateComponent = () => (
    <Box marginTop="12px" direction="vertical">
      <EmptyState
        title={t('collectionsEmptyState.title')}
        subtitle={t('collectionsEmptyState.subtitle')}
        theme="page"
      />
    </Box>
  );

  return (
    <QueryDecorator
      queryFn={() =>
        queryAppsData({
          services,
          experiments,
          slug,
          languageCode,
          offset: 0,
          filter: {
            isSale,
            includeDealerBenefits: experiments.enabled(
              'specs.app-market.AppBenefits',
            ),
          },
        })
      }
      queryKey={queryKeyBuilder({
        queryName: 'initial-apps-fetch',
        language: languageCode,
        isSale,
        appsLength: 0,
        queryId: slug,
      })}
    >
      {({ data, isLoading }) =>
        isLoading ? (
          <AppsSectionSkeleton showSaleToggle={!isSaleCollection} />
        ) : (
          <AppsSection
            origin={pageName}
            queryApps={queryApps}
            queryId={slug}
            initData={data}
            showSaleToggle={!isSaleCollection}
            EmptyState={EmptyStateComponent}
          />
        )
      }
    </QueryDecorator>
  );
}
