import React from 'react';
import {
  useEssentials,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { useTranslation } from '../../contexts/translations-context';
import { SidebarComponent } from './inner-components/sidebar-component';
import { SidebarComponentLoader } from './inner-components/sidebar-skeleton';
import { SidebarService } from './sidebar-service';
import { getCompleteSetupCount, getDynamicSidebarItems } from './sidebar-api';
import { useQueryClient } from '@tanstack/react-query';
import { useComponentLoadReporter } from '../../hooks/component-load-reporter';
import { sidebarComponentId } from './sidebar-constants';

export function Sidebar() {
  const { biLogger, experiments } = useEssentials();
  const { t } = useTranslation();
  const services = useServices();
  const { route, router } = useRouterContext();
  const { metaSiteId, baseURL, languageCode } = useSiteContext();
  const queryClient = useQueryClient();
  const { marketWatch } = useEssentials();

  const moveBundleToBenefits = experiments.enabled(
    'specs.app-market.MoveBundlesToBenefits',
  );

  const queryKey = sidebarComponentId;
  const reportLoadFinish = useComponentLoadReporter({
    componentId: sidebarComponentId,
    params: { route, metaSiteId, languageCode },
    deps: [queryKey],
  });

  if (route.payload?.hideMenu) {
    reportLoadFinish();
    return null;
  }

  return (
    <QueryDecorator
      queryFn={() =>
        Promise.all([
          getDynamicSidebarItems({
            services,
            queryClient,
            marketWatch,
            isMetaSiteId: !!metaSiteId,
          }),
          metaSiteId
            ? getCompleteSetupCount({
                services,
                queryClient,
                marketWatch,
                moveBundleToBenefits,
              })
            : 0,
        ])
      }
      queryKey={queryKey}
    >
      {({ data, isLoading }) => {
        if (isLoading) {
          return <SidebarComponentLoader />;
        }

        const [sections, pendingAppsCount] = data;
        const sidebar = new SidebarService({
          sections,
          route,
          router,
          baseURL,
          t,
          biLogger,
          metaSiteId,
        });

        return (
          <SidebarComponent
            sidebarItems={sidebar.sidebarItems}
            isMetaSiteId={!!metaSiteId}
            selectedItemKey={sidebar.selectedItemKey}
            onItemClick={(item, index) => sidebar.onItemClick(item, index)}
            onQuickNavOpen={(item, index) =>
              sidebar.onQuickNavOpen(item, index)
            }
            onSubItemClick={(item, index, parentItem) =>
              sidebar.onSubItemClick(item, index, parentItem)
            }
            pendingAppsCount={pendingAppsCount}
            shouldShowHeader={sidebar.showHeader}
            shouldShowFooter={sidebar.showFooter}
            onFooterClick={() => sidebar.onFooterClick()}
            onLoad={reportLoadFinish}
          />
        );
      }}
    </QueryDecorator>
  );
}
