import React from 'react';
import { appMarketClickOnCompleteSetup } from '@wix/bi-logger-app-market-data/v2';

import {
  AppNotificationType,
  type AppNotification as AppNotificationDef,
} from './types';
import {
  useEssentials,
  useJunkYard,
  useRouterContext,
} from '../../../../contexts';
import { getRouteBiData } from '../../../../bi-services/utils';
import { RoutePath } from '../../../../models';

import { EditorNotSupportedNotification } from './editor-not-supported/editor-not-supported-notification';
import { SetupIncompleteNotification } from './setup-incomplete/setup-incomplete-notification';
import { OneDependencyNotInstalledNotification } from './one-dependency-not-installed/one-dependency-not-installed-notification';
import { ManyDependenciesNoneInstalledNotification } from './many-dependencies-none-installed/many-dependencies-none-installed-notification';
import { DependencyPendingEditorNotification } from './dependency-pending-editor/dependency-pending-editor-notification';
import { PremiumSiteRequiredNotification } from './premium-site-required/premium-site-required-notification';
import { HeadlessAppInstalledNotification } from './headless-app-installed/headless-app-installed-notification';
import { AppBenefitNotification } from './app-benefit/app-benefit-notification';

export interface AppNotificationProps {
  notification: AppNotificationDef | null;
}

export function AppNotification({ notification }: AppNotificationProps) {
  const { routerData, goBackToReferrer } = useJunkYard();
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();

  if (notification?.type === AppNotificationType.HeadlessAppInstalled) {
    const { appName } = notification.data;
    return <HeadlessAppInstalledNotification appName={appName} />;
  }

  if (notification?.type === AppNotificationType.EditorNotSupported) {
    const { appName, editorType } = notification.data;
    return (
      <EditorNotSupportedNotification
        appName={appName}
        editorType={editorType}
      />
    );
  }

  if (notification?.type === AppNotificationType.PremiumSiteRequired) {
    const { app } = notification.data;
    return <PremiumSiteRequiredNotification app={app} />;
  }

  if (notification?.type === AppNotificationType.SetupIncomplete) {
    const { appId, dashboardUrl, isExternalDashboard } = notification.data;
    return (
      <SetupIncompleteNotification
        onCompleteClick={() => {
          biLogger.report(
            appMarketClickOnCompleteSetup({
              app_id: appId,
              referral_info: getRouteBiData(
                routerData.route,
                routerData.prevRoute,
                goBackToReferrer?.referrerNameForBI,
              ).referralInfo,
            }),
          );
          if (isExternalDashboard) {
            router.navigateTo({
              path: RoutePath.APP_EXTERNAL_DASHBOARD,
              payload: {
                appId,
                url: dashboardUrl,
              },
            });
          } else {
            router.navigateTo({
              path: RoutePath.APP_DASHBOARD,
              payload: {
                appId,
              },
            });
          }
        }}
      />
    );
  }

  if (notification?.type === AppNotificationType.DependenciesNotInstalled) {
    const { app, appDependencies } = notification.data;
    if (appDependencies.length === 1) {
      return (
        <OneDependencyNotInstalledNotification
          app={app}
          appDependency={appDependencies[0]}
        />
      );
    }

    return (
      <ManyDependenciesNoneInstalledNotification
        app={app}
        appDependencies={appDependencies}
      />
    );
  }

  if (notification?.type === AppNotificationType.DependencyPendingEditor) {
    const { app, appDependency } = notification.data;
    return (
      <DependencyPendingEditorNotification
        app={app}
        appDependency={appDependency}
      />
    );
  }

  if (notification?.type === AppNotificationType.AppBenefit) {
    const { appName } = notification.data;
    return <AppBenefitNotification appName={appName} />;
  }

  return null;
}
