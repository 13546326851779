import { RequestService } from '../request-service';
import type RpcModule from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/rpc';
import type HttpModule from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/http';
import type {
  BundleAppsRequest,
  GetBundleAppsResponse,
} from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/types';
import { BundleApp } from './bundle-app';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class BundleAppsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/http');
  }

  public async get(): Promise<{ appId: string }[]> {
    const response = await this.request<
      BundleAppsRequest,
      GetBundleAppsResponse
    >({
      methodName: 'bundleApps',
      payload: {},
    });
    const bundleApps = response.bundleApps?.bundleWebSolutions ?? [];
    return bundleApps.map((bundleApp) => {
      const bundleAppFacade = new BundleApp(bundleApp);
      return { appId: bundleAppFacade.appId };
    });
  }
}
