import React from 'react';
import { InstalledBadge } from '../installed-badge';
import type { PricingBadgeProps } from '../pricing-badge';
import { PricingBadge } from '../pricing-badge';
import { useEssentials } from '../../contexts/essentials-context';
import { SetupIncompleteBadge } from '../setup-incomplete-badge';
import { PremiumSiteRequiredBadge } from '../premium-site-required-badge';
import { Box, Text } from '@wix/design-system';
import { StatusCompleteFilledSmall } from '@wix/wix-ui-icons-common';
import { appInfoBadgeDataHooks } from './data-hooks';

export interface AppInfoBadgeProps {
  isAppInstalled: boolean;
  isAppBuiltIn: boolean;
  isAppSetupIncomplete: boolean;
  isAppWithBenefits: boolean;
  pricing: PricingBadgeProps;
  options: {
    showPricing?: boolean;
    showPremiumRequired: boolean;
    size?: 'tiny' | 'small';
  };
  dataHook?: string;
}

export function AppInfoBadge({
  isAppInstalled,
  isAppBuiltIn,
  isAppSetupIncomplete,
  isAppWithBenefits,
  pricing,
  options,
  dataHook = appInfoBadgeDataHooks.APP_INFO_BADGE,
}: AppInfoBadgeProps) {
  const { t, experiments } = useEssentials();
  const { showPricing = true, showPremiumRequired, size = 'tiny' } = options;
  const replaceInstalledBundleLabel = Boolean(
    experiments?.enabled('specs.app-market.MoveBundlesToBenefits'),
  );
  let children: React.JSX.Element | null;
  if (isAppWithBenefits && !isAppInstalled) {
    children = (
      <Text size={size} weight="thin" skin="success" secondary>
        {t('app.pricing.bundle-free')}
      </Text>
    );
  } else if (isAppWithBenefits && isAppInstalled) {
    children = (
      <InstalledBadge size={size}>
        {replaceInstalledBundleLabel
          ? t('pricing.label.benefit.installed')
          : t('solutionBox.solutionBottomActionBar.BundleInstalled')}
      </InstalledBadge>
    );
  } else if (showPremiumRequired) {
    children = <PremiumSiteRequiredBadge size={size} />;
  } else if (isAppBuiltIn) {
    children = (
      <Box gap="4px" verticalAlign="middle">
        <StatusCompleteFilledSmall color="#60bc57" />
        <Text size={size} skin="success">
          {t('solutionBox.solutionBottomActionBar.builtInSolution')}
        </Text>
      </Box>
    );
  } else if (isAppSetupIncomplete) {
    children = <SetupIncompleteBadge />;
  } else if (isAppInstalled) {
    children = (
      <InstalledBadge size={size}>
        {t('solutionBox.solutionBottomActionBar.installedSolution')}
      </InstalledBadge>
    );
  } else if (showPricing) {
    children = <PricingBadge size={size} {...pricing} />;
  } else {
    children = null;
  }

  return children ? <div data-hook={dataHook}>{children}</div> : null;
}
