import { RequestService } from '../request-service';
import type {
  QueryReviewsRequest,
  QueryReviewsResponse,
  GetUserReviewRequest,
  GetUserReviewResponse,
  UpdateReviewRequest,
  UpdateReviewResponse,
  AppreviewsCreateReviewRequest,
  AppreviewsCreateReviewResponse,
} from '@wix/ambassador-devcenter-v1-review/types';
import type RpcModule from '@wix/ambassador-devcenter-v1-review/rpc';
import type HttpModule from '@wix/ambassador-devcenter-v1-review/http';
import { Reviews } from './reviews';
import { Review } from './review';
import { SortOrder } from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import type {
  CreateReview,
  GetUserReview,
  QueryReviews,
  UpdateReview,
} from './types';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class ReviewsService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-v1-review/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-v1-review/http');
  }

  public async query(payload: QueryReviews): Promise<Reviews> {
    const { appIds, sortMethod, limit, offset } = payload;
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryReviews');
    }

    const response = await this.request<
      QueryReviewsRequest,
      QueryReviewsResponse
    >({
      methodName: 'queryReviews',
      payload: {
        query: {
          filter: {
            appId: {
              $in: appIds,
            },
            publishedAt: {
              $exists: true,
            },
          },
          sort: [{ fieldName: sortMethod, order: SortOrder.DESC }],
          paging: {
            limit,
            offset,
          },
        },
      },
    });
    return new Reviews(response.reviews || []);
  }

  public async getUserReview(payload: GetUserReview): Promise<Review> {
    const response = await this.request<
      GetUserReviewRequest,
      GetUserReviewResponse
    >({
      methodName: 'getUserReview',
      payload,
    });
    return new Review(response.userReview ?? {});
  }

  public async update(payload: UpdateReview): Promise<Review> {
    const response = await this.request<
      UpdateReviewRequest,
      UpdateReviewResponse
    >({
      methodName: 'updateReview',
      payload,
    });
    return new Review(response.review || {});
  }

  public async create(payload: CreateReview): Promise<Review> {
    const response = await this.request<
      AppreviewsCreateReviewRequest,
      AppreviewsCreateReviewResponse
    >({
      methodName: 'appReviewsNamespaceCreateReview',
      payload,
    });
    return new Review(response.review || {});
  }
}
