import { type IRoute, RoutePath } from '../../models';
import type { DynamicSectionType } from './types';
import { SectionType } from '@wix/app-market-services';

export const sidebarComponentId = 'app-market-sidebar';
export const getDynamicSidebarItemsId = 'get-dynamic-sidebar-items';
export const completeSetupCountId = 'complete-setup-count';
export const sidebarReferral = 'menu_navigation';

interface ConstantSidebarKeys {
  [RoutePath.HOME]: string;
  [RoutePath.SEARCH_RESULTS]: string;
  [RoutePath.MANAGE_APPS]: string;
  [RoutePath.UNSUPPORTED]: string;
}

export const constantSidebarKeys: ConstantSidebarKeys = {
  [RoutePath.HOME]: 'home',
  [RoutePath.SEARCH_RESULTS]: 'search-result',
  [RoutePath.MANAGE_APPS]: 'manage-apps',
  [RoutePath.UNSUPPORTED]: 'unsupported',
};

export enum ESidebarItemTypes {
  DIVIDER = 'divider',
  TITLE = 'title',
  LINK = 'link',
  SUB_MENU = 'sub-menu',
}

export interface ConstantSidebarItemData {
  key: string;
  labelTransKey: string;
  route: IRoute;
}

export const constantSidebarItemData: ConstantSidebarItemData[] = [
  {
    key: constantSidebarKeys[RoutePath.HOME],
    labelTransKey: 'sidebar.home',
    route: {
      path: RoutePath.HOME,
      payload: {
        referral: sidebarReferral,
      },
    } as IRoute,
  },
];

export const manageAppsItemData: ConstantSidebarItemData = {
  key: constantSidebarKeys[RoutePath.MANAGE_APPS],
  labelTransKey: 'sidebar.manage-apps',
  route: {
    path: RoutePath.MANAGE_APPS,
    payload: undefined,
  } as IRoute,
};

export const fallbackSidebarSections: DynamicSectionType[] = [
  {
    id: '',
    type: SectionType.SIDEBAR_DIVIDER,
  },
  {
    id: '',
    type: SectionType.SIDEBAR_TITLE,
    sidebarTitle: {
      text: 'CATEGORIES',
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Marketing',
      slug: 'marketing',
      subCategoryLinks: [
        {
          label: 'Ads',
          slug: 'ads',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Mobile',
          slug: 'mobile',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Analytics',
          slug: 'analytics',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Social',
          slug: 'social',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'SEO',
          slug: 'seo',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Engagement',
          slug: 'engagement',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Site Listings',
          slug: 'site-listings',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Email',
          slug: 'email',
          parentCategorySlug: 'marketing',
        },
        {
          label: 'Conversion',
          slug: 'conversion',
          parentCategorySlug: 'marketing',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Sell Online',
      slug: 'ecommerce',
      subCategoryLinks: [
        {
          label: 'Stores',
          slug: 'stores',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Shipping & Delivery',
          slug: 'shipping--delivery',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Sell Buttons',
          slug: 'sell-buttons',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Online Courses',
          slug: 'online-courses',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Print on Demand',
          slug: 'print-on-demand',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Accounting',
          slug: 'accounting',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Coupons & Loyalty',
          slug: 'coupons--loyalty',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Warehousing Solutions',
          slug: 'warehousing-solutions',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Dropshipping',
          slug: 'dropship',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Pricing & Subscription',
          slug: 'pricing--subscription',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Crowdfunding',
          slug: 'crowdfunding',
          parentCategorySlug: 'ecommerce',
        },
        {
          label: 'Food & Beverage',
          slug: 'food-beverage',
          parentCategorySlug: 'ecommerce',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Services & Events',
      slug: 'booking--events',
      subCategoryLinks: [
        {
          label: 'Hotels',
          slug: 'hotels',
          parentCategorySlug: 'booking--events',
        },
        {
          label: 'Events',
          slug: 'events',
          parentCategorySlug: 'booking--events',
        },
        {
          label: 'Restaurants',
          slug: 'restaurants',
          parentCategorySlug: 'booking--events',
        },
        {
          label: 'Real Estate',
          slug: 'real-estate',
          parentCategorySlug: 'booking--events',
        },
        {
          label: 'Bookings',
          slug: 'bookings',
          parentCategorySlug: 'booking--events',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Media & Content',
      slug: 'media--content',
      subCategoryLinks: [
        {
          label: 'Gallery',
          slug: 'gallery',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Music',
          slug: 'music',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Podcasts',
          slug: 'podcasts',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Photography',
          slug: 'photography',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Video',
          slug: 'video',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'PDF',
          slug: 'pdf',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'File Sharing',
          slug: 'file-sharing',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'News',
          slug: 'news',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Content Services',
          slug: 'content-services',
          parentCategorySlug: 'media--content',
        },
        {
          label: 'Text Effects',
          slug: 'text-effects',
          parentCategorySlug: 'media--content',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Design Elements',
      slug: 'design-elements',
      subCategoryLinks: [
        {
          label: 'Maps & Navigation',
          slug: 'maps--navigation',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Privacy & Security',
          slug: 'privacy--security',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Clock',
          slug: 'clock',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Page Templates',
          slug: 'page-templates',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Image Effects',
          slug: 'image-effects',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Buttons & Menus',
          slug: 'buttons--menus',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Banners & Badges',
          slug: 'banners--badges',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Calculators',
          slug: 'calculators',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Search',
          slug: 'search',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Weather',
          slug: 'weather',
          parentCategorySlug: 'design-elements',
        },
        {
          label: 'Charts & Tables',
          slug: 'charts--tables',
          parentCategorySlug: 'design-elements',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.CATEGORY_LINK,
    categoryLink: {
      label: 'Communication ',
      slug: 'communication',
      subCategoryLinks: [
        {
          label: 'Forms',
          slug: 'forms',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Blog',
          slug: 'blogs',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Polls',
          slug: 'polls',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Chat',
          slug: 'chat',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Comments',
          slug: 'comments',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Phone',
          slug: 'phone',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Community',
          slug: 'community',
          parentCategorySlug: 'communication',
        },
        {
          label: 'Reviews & Testimonials',
          slug: 'reviews--testimonials',
          parentCategorySlug: 'communication',
        },
        {
          label: 'CRM',
          slug: 'crm',
          parentCategorySlug: 'communication',
        },
      ],
    },
  },
  {
    id: '',
    type: SectionType.SIDEBAR_DIVIDER,
  },
  {
    id: '',
    type: SectionType.SIDEBAR_TITLE,
    sidebarTitle: {
      text: 'EXPLORE',
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Team Picks',
      slug: 'staff-picks',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Newest Apps',
      slug: 'newest-apps-v2',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Popular This Month',
      slug: 'popular-this-month',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Wix Business Solutions',
      slug: 'wix-business-solutions',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Editor Add-ons',
      slug: 'editors-add-ons',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.SIDEBAR_DIVIDER,
  },
  {
    id: '',
    type: SectionType.SIDEBAR_TITLE,
    sidebarTitle: {
      text: 'APPS TO HELP YOU',
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Collect Leads',
      slug: 'collect-leads',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Analyze Traffic',
      slug: 'analyze-traffic',
      highlighted: false,
    },
  },
  {
    id: '',
    type: SectionType.COLLECTION_LINK,
    collectionLink: {
      label: 'Interact with Visitors',
      slug: 'interact-with-visitors',
      highlighted: false,
    },
  },
];
