import type { PanoramaTransaction, AnyObject } from './types';

export class Transaction {
  private panoramaTransaction: PanoramaTransaction;
  private globalParams: AnyObject;
  private hasStarted = false;
  private hasFinished = false;

  constructor(
    panoramaTransaction: PanoramaTransaction,
    globalParams: AnyObject,
  ) {
    this.panoramaTransaction = panoramaTransaction;
    this.globalParams = globalParams;
  }

  start(startParams?: AnyObject): void {
    if (!this.hasStarted) {
      this.panoramaTransaction.start({ ...this.globalParams, ...startParams });
      this.hasStarted = true;
    }
  }

  finish(finishParams?: AnyObject): void {
    if (!this.hasStarted) {
      throw new Error(
        'Finish transaction should not be initiated before start transaction',
      );
    }
    if (!this.hasFinished) {
      this.panoramaTransaction.finish({
        ...this.globalParams,
        ...finishParams,
      });
      this.hasFinished = true;
    }
  }
}
