import {
  manageAppsUpgradeSite,
  appMarketClickOnFreeTrialCta,
} from '@wix/bi-logger-app-market-data/v2';
import { useEssentials } from '../../../../contexts';

export function useUpgradeButtonBiEvents({
  app,
  origin,
  showRedeemOfferButton,
}: {
  app: {
    id: string;
    instanceId?: string;
  };
  origin: string;
  showRedeemOfferButton: boolean;
}) {
  const { biLogger } = useEssentials();

  return {
    reportUpgradeClick: () => {
      biLogger.report(
        manageAppsUpgradeSite({
          appId: app.id,
          appInstanceId: app.instanceId,
          upgrade_origin: origin,
          cta_type: showRedeemOfferButton ? 'redeem' : 'upgrade',
        }),
      );
    },
    reportFreeTrialClick: () => {
      biLogger.report(
        appMarketClickOnFreeTrialCta({
          appId: app.id,
          appInstanceId: app.instanceId,
          origin,
        }),
      );
    },
  };
}
